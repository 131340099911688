import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosI from "../api/api";
import { toast } from "react-toastify";
import AppContext from "../context/AppContext";
import { useDisconnect } from "wagmi";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { styled } from "@mui/material/styles";
import { getSortCode } from "../utils/functions";
import DialogAlert from "./DialogAlert";
import DialogAddWallet from "./DialogAddWallet";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

const DialogWallets = ({ open, setOpen, getUserData }) => {
	const navigate = useNavigate();
	const { account } = useContext(AppContext);
	const [openDelete, setOpenDelete] = useState(false);
	const [openNewWallet, setOpenNewWallet] = useState(false);
	const [currentWallet, setCurrentWallet] = useState(null);
	const { disconnect } = useDisconnect({
		onError(error) {
			console.log("Error", error);
		},
		onSuccess(data) {
			navigate("/");
		},
	});
	useEffect(() => {
		if (open) {
			setCurrentWallet(null);
			setOpenNewWallet(false);
			setOpenDelete(false);
		}
	}, [open]);

	const handleClose = () => {
		setOpen(false);
	};
	const handleDisconnect = () => {
		disconnect();
	};

	const handleDeleteWallet = (wallet) => {
		setCurrentWallet(wallet);
		setOpenDelete(true);
	};

	const deleteWallet = () => {
		axiosI
			.post("/api/removeWallet", {
				publicKey: currentWallet,
			})
			.then((res) => {
				getUserData();
				handleClose();
				toast.success("Wallet removed successfully ");
			})
			.catch((error) => {
				toast.error("Error during remove wallet");
			});
	};
	return (
		<div>
			{open && (
				<Dialog
					open={open}
					onClose={handleClose}
					className="dialog_ alertDataDialog"
				>
					<DialogAlert
						open={openDelete}
						setOpen={setOpenDelete}
						title="Delete Wallet"
						msg="Are you sure you want to delete this wallet?"
						handleMethod={deleteWallet}
					/>
					<DialogAddWallet
						open={openNewWallet}
						setOpen={setOpenNewWallet}
						getUserData={getUserData}
					/>
					<DialogContent>
						<div className="alertHeader">
							<h4 className="dialogHeader">Wallets</h4>
							<div className="walletDialog">
								<div
									className="swap_remove_Icon"
									style={{ marginRight: "10px" }}
								>
									<IconButton
										color="primary"
										onClick={() => setOpenNewWallet(true)}
									>
										<AddBoxIcon />
									</IconButton>
								</div>

								<div className="swap_remove_Icon">
									<IconButton
										color="primary"
										onClick={() => handleDisconnect()}
									>
										<ExitToAppIcon />
									</IconButton>
								</div>
							</div>
						</div>

						<div className="boxB mempoolAlertBox">
							{Object.keys(account.wallets).map((wallet, i) => {
								const m = account.wallets[wallet];
								return (
									<Grid
										container
										spacing={1}
										className="dialog_form"
										key={i}
										style={{ alignItems: "center" }}
									>
										<Grid
											item
											xs={1}
											className="contentCenter"
										>
											<FormLabel>{i + 1}</FormLabel>
										</Grid>
										<Grid item xs={6} sm={4}>
											{getSortCode(m.snipe_wallet)}
										</Grid>
										<Grid item xs={4} sm={6}>
											{m.name}
										</Grid>
										<Grid
											item
											xs={1}
											className="contentCenter"
										>
											<div className="swap_remove_Icon">
												<IconButton
													color="primary"
													onClick={() =>
														handleDeleteWallet(
															m.snipe_wallet
														)
													}
												>
													<DeleteForeverIcon />
												</IconButton>
											</div>
										</Grid>
									</Grid>
								);
							})}
						</div>
					</DialogContent>
					<DialogActions>
						<div className="historyBtn">
							<Button
								variant="outlined"
								className="btnCancel"
								onClick={() => handleClose()}
							>
								Exit
							</Button>
						</div>
					</DialogActions>
				</Dialog>
			)}
		</div>
	);
};

export default DialogWallets;
