import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbarContainer } from "@mui/x-data-grid";
import dayjs from "dayjs";
import axiosI from "../api/api";
import { toast } from "react-toastify";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import { EtherScan, BscScan } from "./Icons";
import FormLabel from "@mui/material/FormLabel";
import { styled } from "@mui/material/styles";
import { getSortCode, callLink, callTXLink } from "../utils/functions";
const CssTextField = styled(TextField)({
	"& label.Mui-focused": {
		color: "#0FA38E",
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: "#0FA38E",
	},
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: "#0FA38E",
		},
		"&:hover fieldset": {
			borderColor: "#0FA38E",
		},
		"&.Mui-focused fieldset": {
			borderColor: "#0FA38E",
		},
	},
});
const CustomToolbar = () => {
	return (
		<GridToolbarContainer style={{ float: "left" }}></GridToolbarContainer>
	);
};
const DialogHistory = ({ open, setVar, wallet }) => {
	const [data, setData] = useState([]);
	const [pageSize, setPageSize] = useState(10);

	useEffect(() => {
		if (open) {
			loadData();
		}
	}, [open]);

	const handleClose = () => {
		setVar({
			openHistory: false,
		});
	};

	const loadData = () => {
		axiosI
			.post("/api/history", {
				publicKey: wallet,
			})
			.then((res) => {
				setData(res.data.data);
			})
			.catch((error) => {
				toast.error("Error load history");
			});
	};
	const getStatus = (num) => {
		switch (num) {
			case -1:
				return <span className="textPink">Failed</span>;
			case 2:
				return <span className="textGreen">Success</span>;
			default:
				return <span>Processing</span>;
		}
	};

	const columns = [
		{
			field: "ts",
			headerName: "",
			minWidth: 250,
			disableExport: true,
			renderCell: (params) => {
				return (
					<Grid
						container
						spacing={1}
						className="dialog_form historyList"
						style={{ alignItems: "center" }}
					>
						<Grid item xs={4} className="contentCenter">
							<FormLabel>Time:</FormLabel>
						</Grid>
						<Grid item xs={8} className="contentCenter">
							<FormLabel>
								{dayjs(params.row.ts).format("MMM D HH:mm:ss")}
							</FormLabel>
						</Grid>

						<Grid item xs={4} className="contentCenter">
							<FormLabel>Contract:</FormLabel>
						</Grid>
						<Grid item xs={8} className="contentCenter">
							<span>{getSortCode(params.row.contract)}</span>
							<span
								onClick={(e) => {
									e.stopPropagation();
									callLink(
										params.row.network,
										params.row.contract
									);
								}}
								className="iconpos"
							>
								{params.row.network === "ETH" ? (
									<EtherScan />
								) : (
									<BscScan />
								)}
							</span>
						</Grid>
						<Grid item xs={4} className="contentCenter">
							<FormLabel>Tx:</FormLabel>
						</Grid>
						<Grid item xs={8} className="contentCenter">
							<span>{getSortCode(params.row.txhash)}</span>
							{params.row.txhash && (
								<span
									onClick={(e) => {
										e.stopPropagation();
										callTXLink(
											params.row.network,
											params.row.txhash
										);
									}}
									className="iconpos"
								>
									{params.row.network === "ETH" ? (
										<EtherScan />
									) : (
										<BscScan />
									)}
								</span>
							)}
						</Grid>
						<Grid item xs={4} className="contentCenter">
							<FormLabel>Type:</FormLabel>
						</Grid>
						<Grid item xs={8}>
							{params.row.type}
						</Grid>

						<Grid item xs={4} className="contentCenter">
							<FormLabel>Status:</FormLabel>
						</Grid>
						<Grid item xs={8}>
							{getStatus(params.row.status)}
						</Grid>
					</Grid>
				);
			},
		},
	];

	return (
		<div>
			{open && (
				<Dialog
					open={open}
					onClose={handleClose}
					className="dialog_ historyDialog"
				>
					<DialogContent>
						<div className="">
							<h4 className="dialogHeader">History</h4>
						</div>

						<div className="boxB mempoolAlertBox">
							<DataGrid
								className="tableDG"
								getRowHeight={() => "auto"}
								rows={data}
								columns={columns}
								pageSize={pageSize}
								onPageSizeChange={(newPageSize) =>
									setPageSize(newPageSize)
								}
								rowsPerPageOptions={[10, 25, 50, 100]}
								disableColumnMenu
								disableSelectionOnClick
								autoHeight
								components={{
									Toolbar: CustomToolbar,
								}}
								getRowClassName={(params) =>
									params.indexRelativeToCurrentPage % 2 === 0
										? "table_even"
										: "table_odd"
								}
							/>
						</div>
					</DialogContent>
					<DialogActions>
						<div className="historyBtn">
							<Button
								variant="outlined"
								className="btnCancel"
								onClick={() => handleClose()}
							>
								Exit
							</Button>
						</div>
					</DialogActions>
				</Dialog>
			)}
		</div>
	);
};

export default DialogHistory;
