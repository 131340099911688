import React, { useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import LoopIcon from "@mui/icons-material/Loop";
import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DialogSellPanel from "./DialogSellPanel";
import InfoIcon from "@mui/icons-material/Info";
import AddTaskIcon from "@mui/icons-material/AddTask";
import SendIcon from "@mui/icons-material/Send";
import { isAddress } from "../utils/functions";
const CssTextField = styled(TextField)({
	"& label.Mui-focused": {
		color: "#0FA38E",
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: "#0FA38E",
	},
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: "#0FA38E",
		},
		"&:hover fieldset": {
			borderColor: "#0FA38E",
		},
		"&.Mui-focused fieldset": {
			borderColor: "#0FA38E",
		},
	},
});

const SellPanel = ({
	fileds,
	sell,
	approve,
	balance,
	setVar,
	openTrade,
	handleSellTest,
}) => {
	const [open, setOpen] = useState(false);
	const [openPanel, setOpenPanel] = useState(true);

	const handleOpenDialog = (e) => {
		setOpen(true);
		e.stopPropagation();
		e.preventDefault();
		e.stopPropagation();
	};
	const handleChangePanel = (e, newValue) => {
		setOpenPanel(newValue);
	};
	const openTradeDialog = () => {
		if (isAddress(fileds.contract)) {
			setVar({
				tcontract: fileds.contract,
			});
			openTrade();
		}
	};
	return (
		<Accordion expanded={openPanel} onChange={handleChangePanel}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				className="accordion-header"
			>
				<div className="buy_panel_header">
					<div>SELL</div>
					<div></div>
					<div className="buy_panel_header_buttons">
						<div className="swap_header_Icon">
							<IconButton
								color="primary"
								onClick={handleOpenDialog}
							>
								<SettingsIcon />
							</IconButton>
						</div>
					</div>
				</div>
			</AccordionSummary>
			<AccordionDetails>
				<DialogSellPanel
					open={open}
					setOpen={setOpen}
					fileds={fileds}
					setVar={setVar}
				/>
				<Grid container spacing={1} className="dialog_form">
					<Grid item xs={12} sm={4} className="contentCenter">
						<CssTextField
							className="formInput"
							label="All inv."
							id="outlined-size-small"
							value={fileds.allInv}
							onChange={(e) =>
								setVar({
									allInv: e.target.value,
								})
							}
							type="number"
						/>
					</Grid>
					<Grid item xs={6} sm={4} className="contentCenter">
						<CssTextField
							className="formInput"
							label="Profit ETH"
							id="outlined-size-small"
							value={fileds.profitETH}
						/>
					</Grid>
					<Grid item xs={6} sm={4} className="contentCenter">
						<CssTextField
							className="formInput"
							label="Profit %"
							id="outlined-size-small"
							value={fileds.profitPerc}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={1} className="dialog_form">
					<Grid item xs={12} sm={6} className="contentCenter">
						<CssTextField
							className="formInput"
							label="Amount %"
							id="outlined-size-small"
							value={fileds.sellAmount}
							onChange={(e) =>
								setVar({
									sellAmount: e.target.value,
								})
							}
							type="number"
						/>
						<InfoIcon className="infoIcon" />
					</Grid>

					<Grid
						item
						xs={2}
						sm={2}
						className="contentCenter swapmain"
						style={{ justifyContent: "start" }}
					>
						<div className="swap_remove_Icon balance">
							<IconButton
								color="primary"
								onClick={() => openTradeDialog()}
							>
								<AddTaskIcon />
							</IconButton>
						</div>
					</Grid>
					<Grid
						item
						xs={5}
						sm={1}
						className="contentCenter swapmain"
						style={{ justifyContent: "end" }}
					>
						<div className="swap_remove_Icon balance">
							<IconButton
								color="primary"
								onClick={() => balance()}
							>
								<LoopIcon />
							</IconButton>
						</div>
					</Grid>
					<Grid
						item
						xs={5}
						sm={3}
						className="contentCenter swapmain"
						style={{ justifyContent: "end" }}
					>
						<Button
							variant="contained"
							className="btnNew"
							onClick={approve}
						>
							Approve
						</Button>
					</Grid>
				</Grid>

				<div className="button_panel">
					<Button
						variant="contained"
						className="btnNew antimevSell"
						onClick={() => sell(true)}
					>
						AntiMEV Sell
					</Button>
					<Button
						variant="contained"
						className="btnNew normalSell"
						onClick={() => sell(false)}
					>
						Sell
					</Button>
					<div className="swap_remove_Icon">
						<IconButton
							color="primary"
							onClick={() => handleSellTest()}
						>
							<SendIcon />
						</IconButton>
					</div>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};

export default SellPanel;
