import React, { useState, useEffect } from "react";
import {
	bgBG,
	DataGrid,
	GridToolbarContainer,
	GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import axiosI from "../api/api";
import relativeTime from "dayjs/plugin/relativeTime";
import IconButton from "@mui/material/IconButton";
import { EtherScan, BscScan, DexScreener } from "./Icons";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Stack from "@mui/material/Stack";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DialogSellPanel from "./DialogSellPanel";
import SortIcon from "@mui/icons-material/Sort";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddTaskIcon from "@mui/icons-material/AddTask";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Tooltip_ } from "./Tooltip";
import Zoom from "@mui/material/Zoom";
import humanFormat from "human-format";
import DialogAlert from "./DialogAlert";
import DialogAddTrades from "./DialogAddTrades";
import SubtitlesOffIcon from "@mui/icons-material/SubtitlesOff";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import {
	getSortCode,
	callLink,
	callTXLink,
	callNewSite,
	readableNumber,
	priceFormat,
} from "../utils/functions";

const CustomToolbar = () => {
	return (
		<GridToolbarContainer className="tableToolbar2_">
			<GridToolbarQuickFilter className="quickFilter " />
		</GridToolbarContainer>
	);
};
function NoRowsOverlay() {
	return (
		<Stack height="100%" alignItems="center" justifyContent="center">
			No tasks are currently active
		</Stack>
	);
}
const _type = [
	{
		value: "BUY",
		name: "BUY",
	},
	{
		value: "SELL",
		name: "SELL",
	},
];
const _task = [
	{
		value: "PRICE",
		name: "Token Price",
	},
	{
		value: "MC",
		name: "Market Cap",
	},
	{
		value: "PROFIT",
		name: "Profit",
	},
];
const _pairs = [
	{
		value: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
		name: "ETH",
	},
	{
		value: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
		name: "DAI",
	},
	{
		value: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
		name: "USDT",
	},
	{
		value: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
		name: "USDC",
	},
];
const TasksPanel = ({
	fileds,
	setVar,
	data,
	openTrade,
	handleLoadTasks,
	handleDeleteTask,
	publicKey,
}) => {
	dayjs.extend(relativeTime);
	const [open, setOpen] = useState(false);
	const [pageSize, setPageSize] = useState(10);
	const [openPanel, setOpenPanel] = useState(true);
	const [_data, _setData] = useState([]);
	const [historyData, setHistoryData] = useState([]);
	const [openClosed, setOpenClosed] = useState(false);
	const [openDelete, setOpenDelete] = useState(false);
	const [id, setId] = useState("");
	useEffect(() => {
		if (!!data.tasks) {
			_setData(data.tasks);
		} else {
			_setData([]);
		}
	}, [data]);

	const handleOpenDialog = (e) => {
		e.stopPropagation();
		e.preventDefault();
		e.stopPropagation();
		openTrade();
	};
	const handleLoadData = (e) => {
		e.stopPropagation();
		e.preventDefault();
		e.stopPropagation();
		handleLoadTasks();
	};
	const handleOpenAlertDialog = (e, id) => {
		e.stopPropagation();
		e.preventDefault();
		e.stopPropagation();
		setId(id);
		setOpenDelete(true);
	};

	const handleChangePanel = (e, newValue) => {
		setOpenPanel(newValue);
	};

	const deleteTask = () => {
		handleDeleteTask(parseInt(id));
		setOpenDelete(false);
	};
	const editTask = (e, task) => {
		e.stopPropagation();
		e.preventDefault();
		e.stopPropagation();
		let tab = "1";
		if (task.task_type === "FOMO") tab = "2";
		setVar({
			tcontract: !!task.contract ? task.contract : "",
			tname: !!task.name ? task.name : "",
			tswap: !!task.swap ? task.swap : "",
			tfee: !!task.fee ? task.fee : "",
			tvalue: !!task.value ? task.value : "0.1",
			tamount: "",
			tsellamount: !!task.amount ? task.amount : "100",
			tslippage: !!task.slippage ? task.slippage : "100",
			tmanualGas: false,
			tmultiple: !!task.gas_multiple ? task.gas_multiple : "10",
			tGasLimit: !!task.gas_gaslimit ? task.gas_gaslimit : "608000",
			tGasPrice: !!task.gas_gasprice ? task.gas_gasprice : "",
			tmaxFeePerGas: !!task.gas_maxfeepergas ? task.gas_maxfeepergas : "",
			tmaxPriorityFeePerGas: !!task.gas_priorityfeepergas
				? task.gas_priorityfeepergas
				: "",
			tantiMEV: task.antimev,
			tpair: !!task.pair
				? task.pair
				: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
			//tpairToken: "",
			tperc: [task.low_perc * 100, task.high_perc * 100],
			tpercnum: task.perc * 100,
			//openTrade: false,
			tint: task.interval,
			stopLoss: task.stoploss,
			ttype: task.type,
			ttask: task.task_type,
			trouter: task.router,
			//tprice: "",
			//tmc: "",
			//tprofit: "",
			//tstabil: "",
			tid: task.id,
			cond_tsl: task.cond_tsl,
			task_tab: tab,
		});

		openTrade();
	};
	const showType = (t) => {
		const v = _task.find((f) => f.value === t);
		if (!!v) {
			return v.name;
		} else {
			return t;
		}
	};
	const showStable = (t) => {
		const v = _pairs.find((f) => f.value.toLowerCase() === t.toLowerCase());
		if (!!v) {
			return v.name;
		} else {
			return t;
		}
	};
	const showData = (d) => {
		if (d.task_type === "PROFIT" && !!parseFloat(d.start_profit)) {
			return (
				<span className="tradeValue">
					{parseFloat(d.start_profit).toFixed(6)} {showStable(d.pair)}
				</span>
			);
		}
		if (d.task_type === "PRICE") {
			return (
				<span className="tradeValue">{priceFormat(d.start_price)}</span>
			);
		}
		if (d.task_type === "MC") {
			return (
				<span className="tradeValue">
					{parseInt(d.start_mc) > 0
						? "$" +
						  humanFormat(parseInt(d.start_mc), {
								//scale: tokenScale,
								maxDecimals: "auto",
						  })
						: ""}
				</span>
			);
		}
	};
	const getStopLoss = (perc) => {
		if (!!!perc) return "";

		return "(" + parseInt(perc * 100) + "%)";
	};
	const loadHistory = () => {
		axiosI
			.post("/api/closedusertasks", { wallet: publicKey })
			.then(async (res) => {
				setHistoryData(res.data.data);
			})
			.catch((error) => {
				/* toast.error("Network error! Please refresh the site.");
				if (error.response.data.error.code == 5) {
					navigate("/logout");
				} */
			});
	};
	const handleHistory = (e) => {
		e.stopPropagation();
		e.preventDefault();
		e.stopPropagation();
		setOpenClosed(true);
		loadHistory();
	};
	const handleData = (e) => {
		e.stopPropagation();
		e.preventDefault();
		e.stopPropagation();
		setOpenClosed(false);
		handleLoadTasks();
	};
	const columns = [
		{
			field: "name",
			headerName: "Token",
			flex: 1,
			minWidth: 130,
			disableExport: true,
			cellClassName: (params) => {
				let bg = "";
				if (!!params.row.type) {
					if (params.row.type === "BUY") {
						bg = "bgGreen";
					}
					if (params.row.type === "SELL") {
						bg = "bgRed";
					}
				}
				return bg;
			},
			renderCell: (params) => {
				return (
					<div>
						{params.row.task_type === "FOMO" ? (
							<div>
								<div>
									<span>
										Fomo Volume: {params.row.volume}
									</span>
								</div>
								<div>
									<span>
										Max FOMO Triggers:{" "}
										{params.row.task_value}
									</span>
								</div>
							</div>
						) : (
							<div>
								<div>
									<span>{params.row.name}</span>
								</div>
								<div>
									{getSortCode(params.row.contract)}

									<span>
										<span
											onClick={(e) => {
												e.stopPropagation();
												callLink(
													fileds.network,
													params.row.contract
												);
											}}
											className="iconpos"
										>
											{fileds.network === "ETH" ? (
												<EtherScan />
											) : (
												<BscScan />
											)}
										</span>
										<IconButton
											onClick={() =>
												callNewSite(
													`https://dexscreener.com/ethereum/${params.row.contract.toLowerCase()}`
												)
											}
											color="primary"
											style={{
												padding: "0px 10px",
											}}
										>
											<DexScreener width={20} />
										</IconButton>
									</span>
								</div>
							</div>
						)}
					</div>
				);
			},
		},
		{
			field: "id",
			headerName: "",
			flex: 1,
			minWidth: 100,
			maxWidth: 100,
			disableExport: true,
			renderCell: (params) => {
				return (
					<div>
						<div>
							<Tooltip_
								TransitionComponent={Zoom}
								title={
									<span>
										{dayjs(params.row.ts).format(
											"YYYY-MM-DD HH:mm:ss"
										)}
									</span>
								}
							>
								<span>{dayjs(params.row.ts).fromNow()}</span>
							</Tooltip_>
						</div>
						<div>{showType(params.row.task_type)}</div>
					</div>
				);
			},
		},

		{
			field: "value",
			headerName: "Data",
			flex: 1,
			minWidth: 160,
			maxWidth: 160,
			disableExport: true,
			renderCell: (params) => {
				return (
					<div>
						{params.row.task_type === "FOMO" ? (
							<div>
								<div>{params.row.value} ETH</div>
							</div>
						) : (
							<div>
								<div>
									{!!params.row.interval
										? `${
												params.row.low_perc * 100
										  }% ${getStopLoss(
												params.row.stoploss_perc
										  )} <-> ${params.row.high_perc * 100}%`
										: `${params.row.perc * 100}%`}
								</div>
								<div>{showData(params.row)}</div>
							</div>
						)}
					</div>
				);
			},
		},
		{
			field: "symbol",
			headerName: "",
			width: 90,
			disableExport: true,
			renderCell: (params) => {
				return (
					<div>
						<div style={{ float: "right" }}>
							<div className="swap_header_Icon">
								<IconButton
									color="primary"
									onClick={(e) => editTask(e, params.row)}
								>
									<EditIcon />
								</IconButton>
							</div>
							<div className="swap_header_Icon">
								<IconButton
									color="primary"
									onClick={(e) =>
										handleOpenAlertDialog(e, params.row.id)
									}
								>
									<DeleteIcon />
								</IconButton>
							</div>
						</div>
					</div>
				);
			},
		},
	];
	const columnsHistory = [
		{
			field: "name",
			headerName: "Token",
			flex: 1,
			minWidth: 130,
			disableExport: true,
			cellClassName: (params) => {
				let bg = "";
				if (!!params.row.type) {
					if (params.row.type === "BUY") {
						bg = "bgGreen";
					}
					if (params.row.type === "SELL") {
						bg = "bgRed";
					}
				}
				return bg;
			},
			renderCell: (params) => {
				return (
					<div>
						{params.row.task_type === "FOMO" ? (
							<div>
								<div>
									<span>
										Fomo Volume: {params.row.volume}
									</span>
								</div>
								<div>
									<span>
										Max FOMO Triggers:{" "}
										{params.row.task_value}
									</span>
								</div>
							</div>
						) : (
							<div>
								<div>
									<span>{params.row.name}</span>
								</div>
								<div>
									{getSortCode(params.row.contract)}

									<span>
										<span
											onClick={(e) => {
												e.stopPropagation();
												callLink(
													fileds.network,
													params.row.contract
												);
											}}
											className="iconpos"
										>
											{fileds.network === "ETH" ? (
												<EtherScan />
											) : (
												<BscScan />
											)}
										</span>
										<IconButton
											onClick={() =>
												callNewSite(
													`https://dexscreener.com/ethereum/${params.row.contract.toLowerCase()}`
												)
											}
											color="primary"
											style={{
												padding: "0px 10px",
											}}
										>
											<DexScreener width={20} />
										</IconButton>
									</span>
								</div>
							</div>
						)}
					</div>
				);
			},
		},
		{
			field: "id",
			headerName: "",
			flex: 1,
			minWidth: 100,
			maxWidth: 100,
			disableExport: true,
			renderCell: (params) => {
				return (
					<div>
						<div>
							<Tooltip_
								TransitionComponent={Zoom}
								title={
									<span>
										{dayjs(params.row.ts).format(
											"YYYY-MM-DD HH:mm:ss"
										)}
									</span>
								}
							>
								<span>{dayjs(params.row.ts).fromNow()}</span>
							</Tooltip_>
						</div>
						<div>{showType(params.row.task_type)}</div>
					</div>
				);
			},
		},

		{
			field: "value",
			headerName: "Data",
			flex: 1,
			minWidth: 160,
			maxWidth: 160,
			disableExport: true,
			renderCell: (params) => {
				return (
					<div>
						{params.row.task_type === "FOMO" ? (
							<div>
								<div>{params.row.value} ETH</div>
							</div>
						) : (
							<div>
								<div>
									{!!params.row.interval
										? `${
												params.row.low_perc * 100
										  }% ${getStopLoss(
												params.row.stoploss_perc
										  )} <-> ${params.row.high_perc * 100}%`
										: `${params.row.perc * 100}%`}
								</div>
								<div>{showData(params.row)}</div>
							</div>
						)}
					</div>
				);
			},
		},
		{
			field: "symbol",
			headerName: "",
			width: 120,
			disableExport: true,
			renderCell: (params) => {
				return (
					<div>
						<div style={{ float: "right" }}>
							{params.row.status === -1 &&
								!!params.row.txhash && (
									<div className="error_">ERROR</div>
								)}
							{params.row.status === 2 && (
								<div className="success_">SUCCESS</div>
							)}
							{params.row.txhash && (
								<div>
									{getSortCode(params.row.contract)}
									<span>
										<span
											onClick={(e) => {
												e.stopPropagation();
												callTXLink(
													fileds.network,
													params.row.txhash
												);
											}}
											className="iconpos"
										>
											{fileds.network === "ETH" ? (
												<EtherScan />
											) : (
												<BscScan />
											)}
										</span>
									</span>
								</div>
							)}
						</div>
					</div>
				);
			},
		},
	];
	return (
		<Accordion expanded={openPanel} onChange={handleChangePanel}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				className="accordion-header"
			>
				<div className="buy_panel_header">
					<div>TASKS</div>
					<div></div>
					<div className="buy_panel_header_buttons">
						<div className="swap_header_Icon">
							{!openClosed ? (
								<IconButton
									color="primary"
									onClick={(e) => handleHistory(e)}
								>
									<SubtitlesOffIcon />
								</IconButton>
							) : (
								<IconButton
									color="primary"
									onClick={(e) => handleData(e)}
								>
									<SubtitlesIcon />
								</IconButton>
							)}
						</div>
						<div className="swap_header_Icon">
							<IconButton
								color="primary"
								onClick={handleLoadData}
							>
								<RestartAltIcon />
							</IconButton>
						</div>
						<div className="swap_header_Icon">
							<IconButton
								color="primary"
								onClick={handleOpenDialog}
							>
								<AddTaskIcon />
							</IconButton>
						</div>
					</div>
				</div>
			</AccordionSummary>
			<AccordionDetails className="tasks_cont ">
				<DialogSellPanel
					open={open}
					setOpen={setOpen}
					fileds={fileds}
					setVar={setVar}
				/>
				<DialogAlert
					open={openDelete}
					setOpen={setOpenDelete}
					title="Delete Task"
					msg="Are you sure you want to delete this task?"
					handleMethod={deleteTask}
				/>
				<div className="tasks_cont">
					<DataGrid
						className="tableDG"
						rows={openClosed ? historyData : _data}
						columns={openClosed ? columnsHistory : columns}
						pageSize={pageSize}
						onPageSizeChange={(newPageSize) =>
							setPageSize(newPageSize)
						}
						rowsPerPageOptions={[10, 25, 50, 100]}
						disableColumnMenu
						disableSelectionOnClick
						getRowClassName={(params) =>
							params.indexRelativeToCurrentPage % 2 === 0
								? "table_even"
								: "table_odd"
						}
						headerHeight={0}
						components={{
							Toolbar: CustomToolbar,
							NoRowsOverlay,
						}}
					/>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};

export default TasksPanel;
