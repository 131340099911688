import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosI from "../api/api";
import { toast } from "react-toastify";
import AppContext from "../context/AppContext";
import { useDisconnect } from "wagmi";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { styled } from "@mui/material/styles";
import { sleepMs, setDefault, getDefault } from "../utils/functions";
import DialogAlert from "./DialogAlert";
import DialogAddWallet from "./DialogAddWallet";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import CachedIcon from "@mui/icons-material/Cached";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import arraySort from "array-sort";
import { Tooltip_ } from "./Tooltip";
import InfoIcon from "@mui/icons-material/Info";
const CssTextField = styled(TextField)({
	"& label.Mui-focused": {
		color: "#0FA38E",
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: "#0FA38E",
	},
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: "#0FA38E",
		},
		"&:hover fieldset": {
			borderColor: "#0FA38E",
		},
		"&.Mui-focused fieldset": {
			borderColor: "#0FA38E",
		},
	},
});

const DialogMev = ({ open, setVar, publicKey, contract, buy, stopBuy }) => {
	const { account } = useContext(AppContext);
	const [data, setData] = useState([]);

	useEffect(() => {
		if (open) {
			loadData();
		}
	}, [open]);

	const handleClose = () => {
		setVar({
			openMEV: false,
		});
	};

	const loadData = () => {
		if (!!!contract || !!!publicKey) {
			setData([]);
			return false;
		}
		axiosI
			.post("/api/getMevData", {
				publicKey,
				contract,
			})
			.then((res) => {
				res.data.data = arraySort(res.data.data, ["mev_gas"], {
					reverse: true,
				});
				setData(res.data.data);
			})
			.catch((error) => {
				//toast.error("Error loading data");
			});
	};
	const handleRenew = () => {
		if (!!!contract || !!!publicKey) return false;
		if (account.wallets[publicKey].default.methods.length === 0)
			return false;
		stopBuy().then(async (d) => {
			await sleepMs(500);
			buy();
			await sleepMs(500);
			loadData();
			/* setVar({
				processing: true,
			});
			_default[publicKey]._processing = true;
			setDefault(publicKey, { ..._default[publicKey] }); */
		});
	};
	const prevBundleCheck = (data, tx) => {
		const avg = parseFloat(data[0].mev_gas) / 2;
		if (avg <= parseFloat(tx.mev_gas)) {
			return true;
		}
		return false;
	};
	return (
		<div>
			{open && (
				<Dialog
					open={open}
					onClose={handleClose}
					className="dialog_ alertDataDialog"
				>
					<DialogContent>
						<div className="alertHeader">
							<h4 className="dialogHeader">
								Bribe position list
							</h4>
							<div className="walletDialog">
								<div className="swap_remove_Icon">
									<IconButton
										color="primary"
										onClick={() => loadData()}
									>
										<CachedIcon />
									</IconButton>
								</div>
							</div>
						</div>
						<div className="boxB">
							<Table>
								<TableHead className="mevtableheader">
									<TableRow>
										<TableCell>Rank</TableCell>
										<TableCell>Investment</TableCell>
										<TableCell>Fee</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.map((tx, i) => {
										let c = "mevtablerow";
										if (tx.own) {
											c += " bribeM_";
										}
										const isBundle = prevBundleCheck(
											data,
											tx
										);
										if (isBundle) {
											c += " bundle_";
										}
										return (
											<TableRow key={i} className={c}>
												<TableCell>{i + 1}</TableCell>
												<TableCell>
													<div>
														<div>
															<span>Value: </span>
															{tx.value}
														</div>
														{!!tx.amount && (
															<div>
																<span>
																	Amount:{" "}
																</span>
																{tx.amount}
															</div>
														)}
													</div>
												</TableCell>
												<TableCell>
													{tx.mev_gas}
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</div>
						<div className="bribeRenewC">
							<div className="bribeFee">
								<CssTextField
									className="formInput"
									label="Bribe Max Priority"
									id="mevGas"
									value={
										account.wallets[publicKey].default
											.mevGas
									}
									onChange={(e) =>
										setVar({
											mevGas: e.target.value,
										})
									}
									type="number"
								/>
								<Tooltip_
									title="When buying Token amount, the Value is the Maximum you will pay. If you have allocated more that required, the excess funds are returned to you."
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
							</div>
							<Button
								variant="outlined"
								className="btnCancel bribeBtn"
								onClick={() => handleRenew()}
							>
								Renew
							</Button>
						</div>
					</DialogContent>
					<DialogActions>
						<div className="historyBtn">
							<Button
								variant="outlined"
								className="btnCancel"
								onClick={() => handleClose()}
							>
								Exit
							</Button>
						</div>
					</DialogActions>
				</Dialog>
			)}
		</div>
	);
};

export default DialogMev;
