import React from "react";
import Button from "@mui/material/Button";
const Logout = () => {
	const handleLogin = () => {
		window.location.reload();
	};
	return (
		<div className="logout">
			<div>
				<h2>Session expired!</h2>
				<div>Please login again to Readyswap!</div>
				<div className="logoutBtn">
					<Button
						variant="contained"
						className="btnNew"
						onClick={() => handleLogin()}
					>
						Refresh
					</Button>
				</div>
			</div>
		</div>
	);
};

export default Logout;
