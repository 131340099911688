import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import humanFormat from "human-format";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { Tooltip_ } from "./Tooltip";
import { styled } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";
import Slider from "@mui/material/Slider";
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import { callLink, callNewSite, priceFormat } from "../utils/functions";
import DialogTradeSettings from "./DialogTradeSettings";
import { EtherScan, BscScan, DexScreener } from "./Icons";
const CssTextField = styled(TextField)({
	"& label.Mui-focused": {
		color: "#0FA38E",
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: "#0FA38E",
	},
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: "#0FA38E",
		},
		"&:hover fieldset": {
			borderColor: "#0FA38E",
		},
		"&.Mui-focused fieldset": {
			borderColor: "#0FA38E",
		},
	},
});
const marks = [
	{
		value: -100,
		label: "-100%",
	},
	{
		value: 0,
		label: "0%",
	},
	{
		value: 100,
		label: "100%",
	},
	{
		value: 200,
		label: "200%",
	},
	{
		value: 300,
		label: "300%",
	},
	{
		value: 400,
		label: "400%",
	},
	{
		value: 500,
		label: "500%",
	},
];

const _type = [
	{
		value: "BUY",
		name: "BUY",
	},
	{
		value: "SELL",
		name: "SELL",
	},
];
const _task = [
	{
		value: "PRICE",
		name: "Token Price",
	},
	{
		value: "MC",
		name: "Market Cap",
	},
	{
		value: "PROFIT",
		name: "Token Value",
	},
];
function valuetext(value) {
	return `${value}%`;
}
const DialogAddTrades = ({ open, fileds, setVar, saveTrade, saveFomo }) => {
	const [openS, setOpenS] = useState(false);

	const handleChangeTab = (event, newValue) => {
		setVar({
			task_tab: newValue,
		});
	};

	const handleChange = (event, newValue) => {
		if (newValue[0] > 0) newValue[0] = 0;
		if (newValue[1] < 0) newValue[1] = 0;
		setVar({
			tperc: newValue,
		});
	};
	const handleChangeNum = (event, newValue) => {
		setVar({
			tpercnum: newValue,
		});
	};

	const handleClose = () => {
		setVar({
			openTrade: false,
			tcontract: "",
			tprice: "",
			tmc: "",
			tprofit: "",
			tstabil: "",
			tswap: "",
			tfee: "",
			tname: "",
			tpairToken: "",
			tid: "",
			task_tab: "1",
		});
	};
	const handleSetLow = (value) => {
		if (value > 0) value = 0;
		setVar({
			tperc: [value, fileds.tperc[1]],
		});
	};
	const handleSetHigh = (value) => {
		if (value < 0) value = 0;
		setVar({
			tperc: [fileds.tperc[0], value],
		});
	};
	const handlePerc = (value) => {
		setVar({
			tpercnum: value,
		});
	};
	const handleOpenDialog = () => {
		setOpenS(true);
	};

	const handleSave = (e) => {
		if (fileds.task_tab === "1") {
			saveTrade(!!fileds.tid ? fileds.tid : "");
		}
		if (fileds.task_tab === "2") {
			saveFomo(!!fileds.tid ? fileds.tid : "");
		}
		handleClose();
	};

	return (
		<div>
			{open && (
				<Dialog
					open={open}
					onClose={handleClose}
					className="dialog_ buyPanelDialog"
				>
					<DialogTradeSettings
						open={openS}
						setOpen={setOpenS}
						fileds={fileds}
						setVar={setVar}
					/>
					<DialogContent>
						<TabContext value={fileds.task_tab}>
							<Box
								sx={{ borderBottom: 2, borderColor: "divider" }}
							>
								<TabList
									onChange={handleChangeTab}
									className="tabTask"
									indicatorColor="secondary"
									textColor="inherit"
									variant="scrollable"
									scrollButtons="auto"
								>
									<Tab label="Trade" value="1" />
									<Tab label="Fomo" value="2" />
									<Tab label="CopyTrade " value="3" />
									<Tab label="Trending" value="4" />
									<Tab label="SuperSignals" value="5" />
								</TabList>
							</Box>
							<TabPanel value="1">
								<div>
									<Grid
										container
										spacing={1}
										className="dialog_form"
									>
										<Grid
											item
											xs={12}
											className="contentCenter"
										>
											<CssTextField
												className="formInput"
												label="Token address"
												id="taddress"
												value={fileds.tcontract}
												onChange={(e) =>
													setVar({
														tcontract:
															e.target.value,
													})
												}
											/>
											<Tooltip_
												title="The Contract Address of the token you own, or want to execute set a task, like buy or sell after meeting your parameters."
												enterTouchDelay={0}
											>
												<InfoIcon className="infoIcon" />
											</Tooltip_>
										</Grid>
									</Grid>
									<Grid
										container
										spacing={1}
										className="dialog_form tradeInfo_"
									>
										<div className="tradeTokenName">
											Name:{" "}
											<span className="tradeValue">
												{fileds.tname}
											</span>
											<span>
												<span
													onClick={(e) => {
														e.stopPropagation();
														callLink(
															fileds.network,
															fileds.tcontract
														);
													}}
													className="iconpos"
												>
													{fileds.network ===
													"ETH" ? (
														<EtherScan />
													) : (
														<BscScan />
													)}
												</span>
												<IconButton
													onClick={() =>
														callNewSite(
															`https://dexscreener.com/ethereum/${fileds.tcontract.toLowerCase()}`
														)
													}
													color="primary"
												>
													<DexScreener width={25} />
												</IconButton>
											</span>
										</div>
										<div className="tradeTokenData">
											<div>
												Token Value:{" "}
												<span className="tradeValue">
													{fileds.tprofit}{" "}
													{fileds.tstabil}
												</span>
											</div>
											<div>
												Token Price:{" "}
												<span className="tradeValue">
													{priceFormat(fileds.tprice)}
												</span>
											</div>
											<div>
												Market Cap:{" "}
												<span className="tradeValue">
													{parseInt(fileds.tmc) > 0
														? "$" +
														  humanFormat(
																parseInt(
																	fileds.tmc
																),
																{
																	//scale: tokenScale,
																	maxDecimals:
																		"auto",
																}
														  )
														: ""}
												</span>
											</div>
										</div>
									</Grid>
									{!!fileds.tpairToken && (
										<Grid
											container
											spacing={1}
											className="dialog_form"
										>
											<Grid
												item
												xs={12}
												className="contentCenter"
											>
												<iframe
													height="380px"
													width="100%"
													title="Trading Chart"
													src={`https://dexscreener.com/ethereum/${fileds.tcontract}?embed=1&theme=dark&trades=0&info=0`}
												></iframe>
											</Grid>
										</Grid>
									)}

									<Grid
										container
										spacing={1}
										className="dialog_form"
									>
										<Grid
											item
											xs={6}
											className="contentCenter"
										>
											<Paper
												elevation={3}
												sx={{
													p: "2px 4px",
													display: "flex",
													alignItems: "center",
												}}
												className="selectField_form"
											>
												<Select
													id="network-select"
													value={fileds.ttype}
													onChange={(event) => {
														setVar({
															ttype: event.target
																.value,
														});
													}}
												>
													{_type.map((m) => (
														<MenuItem
															key={m.value}
															value={m.value}
															className="pairItem"
														>
															<span>
																{m.name}
															</span>
														</MenuItem>
													))}
												</Select>
											</Paper>
											<Tooltip_
												title="Set whether you are looking to acquire or divest yourself of the token."
												enterTouchDelay={0}
											>
												<InfoIcon className="infoIcon" />
											</Tooltip_>
										</Grid>
										<Grid
											item
											xs={6}
											className="contentCenter"
										>
											<Paper
												elevation={3}
												sx={{
													p: "2px 4px",
													display: "flex",
													alignItems: "center",
												}}
												className="selectField_form"
											>
												<Select
													id="network-select"
													value={fileds.ttask}
													onChange={(event) => {
														setVar({
															ttask: event.target
																.value,
														});
													}}
												>
													{_task.map((m) => (
														<MenuItem
															key={m.value}
															value={m.value}
															className="pairItem"
														>
															<span>
																{m.name}
															</span>
														</MenuItem>
													))}
												</Select>
											</Paper>
											<Tooltip_
												title="Select method of calculation. Token Price, Market Price and Token Value (ETH). IMPORTANT: This calculation is made from when the Task is SET, not ATH or other figure. Eg if Marketcap was $1M and is currently $500k and you set to sell 100% at 100%, you are looking to exit at previous high of $1Million ($500k+100%), not ATH +100%."
												enterTouchDelay={0}
											>
												<InfoIcon className="infoIcon" />
											</Tooltip_>
										</Grid>
										{fileds.ttype === "BUY" ? (
											<Grid
												item
												xs={6}
												sm={6}
												className="contentCenter"
											>
												<CssTextField
													className="formInput"
													label="Value"
													id="tvalue"
													value={fileds.tvalue}
													onChange={(e) =>
														setVar({
															tvalue: e.target
																.value,
														})
													}
												/>
												<Tooltip_
													title="What percentage of your tokens are you allocating to the task on a SELL transaction and ETH value on a BUY transaction."
													enterTouchDelay={0}
												>
													<InfoIcon className="infoIcon" />
												</Tooltip_>
											</Grid>
										) : (
											<Grid
												item
												xs={6}
												sm={6}
												className="contentCenter"
											>
												<CssTextField
													className="formInput"
													label="Amount %"
													id="tsellamount"
													value={fileds.tsellamount}
													onChange={(e) =>
														setVar({
															tsellamount:
																e.target.value,
														})
													}
												/>
												<Tooltip_
													title="What percentage of your tokens are you allocating to the task on a SELL transaction and ETH value on a BUY transaction."
													enterTouchDelay={0}
												>
													<InfoIcon className="infoIcon" />
												</Tooltip_>
											</Grid>
										)}
										<Grid
											item
											xs={4}
											sm={2}
											className="contentCenter"
										>
											<FormControlLabel
												className="antiMEV"
												control={
													<Switch
														color="primary"
														id="tint"
														checked={fileds.tint}
														onChange={(e) => {
															setVar({
																tint: e.target
																	.checked,
															});
														}}
													/>
												}
												label="Interval"
												labelPlacement="top"
											/>
											<Tooltip_
												title="Will set 2 tasks in one, eg SELL, if Price (or MCap) falls 40% OR if price increases 300%. "
												enterTouchDelay={0}
											>
												<InfoIcon className="infoIcon" />
											</Tooltip_>
										</Grid>
										<Grid
											item
											xs={10}
											sm={2}
											className="contentCenter"
										>
											{fileds.ttype === "SELL" &&
												fileds.tint && (
													<>
														{" "}
														<FormControlLabel
															className="antiMEV centerLabel"
															control={
																<Switch
																	color="primary"
																	id="stopLoss"
																	checked={
																		fileds.stopLoss
																	}
																	onChange={(
																		e
																	) => {
																		setVar({
																			stopLoss:
																				e
																					.target
																					.checked,
																		});
																	}}
																/>
															}
															label="Trailing Stoploss"
															labelPlacement="top"
														/>
														<Tooltip_
															title={
																<p>
																	Set a
																	stoploss
																	that
																	increases
																	with the
																	current
																	price, but
																	does not
																	fall once a
																	new ceiling
																	is reached.
																	Stoploss is
																	calculated
																	from the
																	TIME/PRICE
																	the task is
																	set.{" "}
																	<p>
																		Example:
																		If
																		Trailing
																		stoploss
																		is set
																		at 40%
																		and
																		price
																		rises
																		100%
																		without
																		dropping,
																		then the
																		stoploss
																		would
																		rise
																		100%
																		behind.
																		If,
																		subsequently
																		price
																		fell 50%
																		from
																		that new
																		high,
																		your
																		stoploss
																		would
																		get
																		triggered
																		exiting
																		with
																		+60%
																		gain.
																	</p>
																	<p>
																		As with
																		all
																		limit
																		orders,
																		they are
																		executed
																		on best
																		effort
																		basis.
																		If price
																		pushes
																		quickly
																		through
																		your buy
																		or sell
																		orders -
																		as is
																		common
																		in
																		crypto -
																		you will
																		get an
																		execution
																		different
																		than
																		specified.
																		There is
																		also
																		Blockchain
																		swap
																		transaction
																		time to
																		consider
																		which
																		can add
																		to
																		slippage
																		from
																		your
																		expectations.
																		-Liquidity
																		is an
																		execution
																		factor
																		in any
																		market,
																		but
																		particularly
																		relevant
																		in
																		Crypto.
																	</p>
																</p>
															}
															enterTouchDelay={0}
														>
															<InfoIcon className="infoIcon" />
														</Tooltip_>
													</>
												)}
										</Grid>

										<Grid
											item
											xs={2}
											className="contentCenter swapmain "
											style={{ justifyContent: "end" }}
										>
											<div className="swap_autoset_Icon">
												<IconButton
													color="primary"
													onClick={handleOpenDialog}
												>
													<SettingsIcon />
												</IconButton>
											</div>
										</Grid>
									</Grid>
									<Grid
										container
										spacing={1}
										className="dialog_form"
									>
										{!!fileds.tint ? (
											<>
												<Grid
													item
													xs={6}
													className="contentCenter"
												>
													<CssTextField
														className="formInput"
														label="Low %"
														id="lowP"
														value={fileds.tperc[0]}
														onChange={(e) =>
															handleSetLow(
																e.target.value
															)
														}
														type="number"
													/>
													<Tooltip_
														title="The percentage below the current price/marketcap at the time of setting task, that the the task will execute. Cannot be above 0."
														enterTouchDelay={0}
													>
														<InfoIcon className="infoIcon" />
													</Tooltip_>
												</Grid>
												<Grid
													item
													xs={6}
													className="contentCenter"
												>
													<CssTextField
														className="formInput"
														label="High %"
														id="highP"
														value={fileds.tperc[1]}
														onChange={(e) => {
															handleSetHigh(
																e.target.value
															);
														}}
														type="number"
													/>
													<Tooltip_
														title="The percentage above  the current price/marketcap  at the time of setting task, that the the task will execute. Cannot be below 0."
														enterTouchDelay={0}
													>
														<InfoIcon className="infoIcon" />
													</Tooltip_>
												</Grid>
											</>
										) : (
											<Grid
												item
												xs={6}
												className="contentCenter"
											>
												<CssTextField
													className="formInput"
													label="Percentage %"
													id="perc_"
													value={fileds.tpercnum}
													onChange={(e) => {
														handlePerc(
															e.target.value
														);
													}}
													type="number"
												/>
												<Tooltip_
													title="What % change you set to your task. Can be a negative, eg -50 or a positive eg, 500."
													enterTouchDelay={0}
												>
													<InfoIcon className="infoIcon" />
												</Tooltip_>
											</Grid>
										)}
									</Grid>
									<Grid
										container
										spacing={1}
										className="dialog_form"
									>
										<Grid
											item
											xs={12}
											className="contentCenter slider_"
										>
											{!!fileds.tint ? (
												<Slider
													min={-100}
													step={10}
													max={500}
													value={fileds.tperc}
													onChange={handleChange}
													track="normal"
													getAriaValueText={valuetext}
													marks={marks}
													className="tradeSlider"
												/>
											) : (
												<Slider
													min={-100}
													step={10}
													max={500}
													track={
														fileds.tpercnum > 0
															? "normal"
															: "inverted"
													}
													value={fileds.tpercnum}
													onChange={handleChangeNum}
													getAriaValueText={valuetext}
													marks={marks}
													className="tradeSlider"
												/>
											)}
										</Grid>
									</Grid>
								</div>
							</TabPanel>
							<TabPanel value="2">
								<div>
									<Grid
										container
										spacing={1}
										className="dialog_form"
									>
										<Grid
											item
											xs={6}
											sm={6}
											className="contentCenter"
										>
											<CssTextField
												className="formInput"
												label="Set FOMO target"
												id="fomo"
												value={fileds.fomo}
												onChange={(e) =>
													setVar({
														fomo: e.target.value,
													})
												}
											/>
											<Tooltip_
												title="Assign the minimum number of Readyswap users to scan for a token. Once this number is reached, the FOMO trigger is set and you will also start to scan with this wallet address, and will enter on launch with the ETH amount set below."
												enterTouchDelay={0}
											>
												<InfoIcon className="infoIcon" />
											</Tooltip_>
										</Grid>
										<Grid
											item
											xs={6}
											sm={6}
											className="contentCenter"
										>
											<CssTextField
												className="formInput"
												label="Max FOMO Triggers"
												id="maxFomoTx"
												value={fileds.maxFomoTx}
												onChange={(e) =>
													setVar({
														maxFomoTx:
															e.target.value,
													})
												}
											/>
											<Tooltip_
												title="Set the number of FOMO target new launch token buys you want to attempt.	Eg, set 4 and you will set scan to buy 4 tokens that hit your FOMO target amount"
												enterTouchDelay={0}
											>
												<InfoIcon className="infoIcon" />
											</Tooltip_>
										</Grid>
										<Grid
											item
											xs={6}
											sm={6}
											className="contentCenter"
										>
											<CssTextField
												className="formInput"
												label="ETH value"
												id="fomoValue"
												value={fileds.fomoValue}
												onChange={(e) =>
													setVar({
														fomoValue:
															e.target.value,
													})
												}
											/>
											<Tooltip_
												title="The eth value you assign to your FOMO trades."
												enterTouchDelay={0}
											>
												<InfoIcon className="infoIcon" />
											</Tooltip_>
										</Grid>
										<Grid
											item
											xs={6}
											className="contentCenter swapmain "
											style={{ justifyContent: "end" }}
										>
											<div className="swap_autoset_Icon">
												<IconButton
													color="primary"
													onClick={handleOpenDialog}
												>
													<SettingsIcon />
												</IconButton>
											</div>
										</Grid>
									</Grid>
								</div>
							</TabPanel>
							<TabPanel value="3">
								<div>
									<Grid
										container
										spacing={1}
										className="dialog_form"
									>
										Coming Soon!
									</Grid>
								</div>
							</TabPanel>
							<TabPanel value="4">
								<div>
									<Grid
										container
										spacing={1}
										className="dialog_form"
									>
										Coming Soon!
									</Grid>
								</div>
							</TabPanel>
							<TabPanel value="5">
								<div>
									<Grid
										container
										spacing={1}
										className="dialog_form"
									>
										Coming Soon!
									</Grid>
								</div>
							</TabPanel>
						</TabContext>
					</DialogContent>
					<DialogActions>
						<div className="">
							<Button
								variant="outlined"
								className="btnCancel"
								onClick={() => handleClose()}
							>
								Exit
							</Button>
							<Button
								variant="outlined"
								className="btnCancel"
								onClick={(e) => {
									handleSave(e);
								}}
							>
								{!!fileds.tid ? "Edit" : "Save"}
							</Button>
						</div>
					</DialogActions>
				</Dialog>
			)}
		</div>
	);
};

export default DialogAddTrades;
