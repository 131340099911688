import React, { useState, useEffect } from "react";
import {
	bgBG,
	DataGrid,
	GridToolbarContainer,
	GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import IconButton from "@mui/material/IconButton";
import { EtherScan, BscScan, DexScreener } from "./Icons";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Stack from "@mui/material/Stack";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DialogSellPanel from "./DialogSellPanel";
import SortIcon from "@mui/icons-material/Sort";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddTaskIcon from "@mui/icons-material/AddTask";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Tooltip_ } from "./Tooltip";
import Zoom from "@mui/material/Zoom";
import humanFormat from "human-format";
import {
	getSortCode,
	callLink,
	callTXLink,
	callNewSite,
	readableNumber,
} from "../utils/functions";

const CustomToolbar = () => {
	return (
		<GridToolbarContainer className="tableToolbar_">
			<GridToolbarQuickFilter className="quickFilter" />
		</GridToolbarContainer>
	);
};
function NoRowsOverlay() {
	return (
		<Stack height="100%" alignItems="center" justifyContent="center">
			No trades are currently available
		</Stack>
	);
}
const TradesPanel = ({
	fileds,
	data,
	setVar,
	balance,
	getTokens,
	openTrade,
}) => {
	dayjs.extend(relativeTime);
	const [open, setOpen] = useState(false);
	const [pageSize, setPageSize] = useState(10);
	const [openPanel, setOpenPanel] = useState(true);
	const [_data, _setData] = useState([]);
	useEffect(() => {
		if (!!data.tokens) {
			_setData(data.tokens);
		} else {
			_setData([]);
		}
	}, [data]);
	const handleOpenDialog = (e, token) => {
		e.stopPropagation();
		e.preventDefault();
		e.stopPropagation();
		setVar({ tcontract: token });
		openTrade();
	};
	const handlegetTokens = (e) => {
		e.stopPropagation();
		e.preventDefault();
		e.stopPropagation();
		getTokens();
	};
	const handleChangePanel = (e, newValue) => {
		setOpenPanel(newValue);
	};
	const loadContract = (e, token) => {
		e.stopPropagation();
		e.preventDefault();
		e.stopPropagation();
		setVar({ contract: token });
		setTimeout(() => {
			balance();
		}, 500);
	};

	const showPerc = (perc) => {
		let c = "minusPerc";
		if (perc > 0) {
			c = "plusPerc";
		}

		return <span className={c}>{perc}%</span>;
	};

	const columns = [
		{
			field: "name",
			headerName: "Token",
			flex: 1,
			minWidth: 160,
			sortable: false,
			renderCell: (params) => {
				return (
					<div>
						<div>
							<span>{params.row.name}</span>
						</div>
						<div className="subString">
							{getSortCode(params.row.token)}

							<span>
								<span
									onClick={(e) => {
										e.stopPropagation();
										callLink(
											fileds.network,
											params.row.token
										);
									}}
									className="iconpos"
								>
									{fileds.network === "ETH" ? (
										<EtherScan />
									) : (
										<BscScan />
									)}
								</span>
								<IconButton
									onClick={() =>
										callNewSite(
											`https://dexscreener.com/ethereum/${params.row.token.toLowerCase()}`
										)
									}
									color="primary"
									style={{
										padding: "0px 10px",
									}}
								>
									<DexScreener width={20} />
								</IconButton>
							</span>
						</div>
					</div>
				);
			},
		},
		{
			field: "price",
			headerName: "Price",
			flex: 1,
			minWidth: 120,
			maxWidth: 140,
			//width: 140,
			sortable: false,
			renderCell: (params) => {
				return (
					<div>
						<div className="subString">
							<Tooltip_
								TransitionComponent={Zoom}
								title={
									<span>
										{dayjs(params.row.ts).format(
											"YYYY-MM-DD HH:mm:ss"
										)}
									</span>
								}
							>
								<span>{dayjs(params.row.ts).fromNow()}</span>
							</Tooltip_>
						</div>
						<div className="_String">
							<Tooltip_
								TransitionComponent={Zoom}
								title={<span>Total Market Cap</span>}
							>
								<span className="mc_">
									$
									{humanFormat(parseInt(params.row.mc), {
										//scale: tokenScale,
										maxDecimals: "auto",
									})}
								</span>
							</Tooltip_>
						</div>
					</div>
				);
			},
		},
		{
			field: "value",
			headerName: "Value",
			flex: 1,
			minWidth: 120,
			maxWidth: 140,
			//width: 140,
			sortable: false,
			renderCell: (params) => {
				return (
					<div>
						<div className="subString">
							<Tooltip_
								TransitionComponent={Zoom}
								title={
									<span>{`${params.row.buy} -> ${
										params.row.sell +
										parseFloat(params.row.value)
									}`}</span>
								}
							>
								{showPerc(params.row.perc)}
							</Tooltip_>
						</div>
						<div className="_String">
							{params.row.value} {params.row.symbol}
						</div>
					</div>
				);
			},
		},
		{
			field: "symbol",
			headerName: "",
			width: 90,
			sortable: false,
			renderCell: (params) => {
				return (
					<div>
						<div style={{ float: "right" }}>
							<div className="swap_header_Icon">
								<IconButton
									color="primary"
									onClick={(e) =>
										handleOpenDialog(e, params.row.token)
									}
								>
									<AddTaskIcon />
								</IconButton>
							</div>
							<div className="swap_header_Icon">
								<IconButton
									color="primary"
									onClick={(e) =>
										loadContract(e, params.row.token)
									}
								>
									<ArrowCircleRightIcon />
								</IconButton>
							</div>
						</div>
					</div>
				);
			},
		},
	];
	return (
		<Accordion expanded={openPanel} onChange={handleChangePanel}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				className="accordion-header"
			>
				<div className="buy_panel_header">
					<div>TOKENS</div>
					<div></div>
					<div className="buy_panel_header_buttons">
						<div className="swap_header_Icon">
							<IconButton
								color="primary"
								onClick={handlegetTokens}
							>
								<RestartAltIcon />
							</IconButton>
						</div>
					</div>
				</div>
			</AccordionSummary>
			<AccordionDetails className="tokens_">
				<DialogSellPanel
					open={open}
					setOpen={setOpen}
					fileds={fileds}
					setVar={setVar}
				/>

				<div className="tokens_cont">
					<DataGrid
						className="tableDG"
						rows={_data}
						columns={columns}
						pageSize={pageSize}
						onPageSizeChange={(newPageSize) =>
							setPageSize(newPageSize)
						}
						rowsPerPageOptions={[10, 25, 50, 100]}
						disableSelectionOnClick
						disableColumnMenu
						disableColumnSelector
						getRowClassName={(params) =>
							params.indexRelativeToCurrentPage % 2 === 0
								? "table_even"
								: "table_odd"
						}
						columnHeaderHeight={0}
						components={{
							Toolbar: CustomToolbar,
							NoRowsOverlay,
						}}
					/>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};

export default TradesPanel;
