import React, { useState, useContext, useEffect } from "react";

import axiosI from "../api/api";
import { toast } from "react-toastify";
import AppContext from "../context/AppContext";
import { Tooltip_ } from "./Tooltip";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import InfoIcon from "@mui/icons-material/Info";
import FormLabel from "@mui/material/FormLabel";
import { callNewSite } from "../utils/functions";
import { styled } from "@mui/material/styles";

const CssTextField = styled(TextField)({
	"& label.Mui-focused": {
		color: "#0FA38E",
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: "#0FA38E",
	},
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: "#0FA38E",
		},
		"&:hover fieldset": {
			borderColor: "#0FA38E",
		},
		"&.Mui-focused fieldset": {
			borderColor: "#0FA38E",
		},
	},
});

const DialogAddWallet = ({ open, setOpen, getUserData }) => {
	const { account } = useContext(AppContext);
	const [name, setName] = useState("");
	const [publicKey, setPublicKey] = useState("");
	const [pk, setPK] = useState("");

	useEffect(() => {
		if (open) {
			setName("");
			setPublicKey("");
			setPK("");
		}
	}, [open]);

	const handleClose = () => {
		setOpen(false);
	};

	const addNewWallet = () => {
		const cW = Object.keys(account.wallets).find(
			(f) => f.toLowerCase() === publicKey.toLowerCase()
		);
		if (!!cW) return toast.warn("Duplicate wallet");
		if (Object.keys(account.wallets).length >= 100)
			return toast.warn("Currently you can add only 100 trader wallet");

		if (!!!publicKey || !!!pk) {
			return toast.warn("Please enter public and private keys!");
		}

		axiosI
			.post("/api/addNewWallet", {
				name,
				publicKey: publicKey,
				privateKey: pk,
			})
			.then((res) => {
				getUserData();
				handleClose();
				toast.success("Wallet added successfully");
			})
			.catch((error) => {
				toast.error("Error during adding wallet");
			});
	};

	return (
		<div>
			{open && (
				<Dialog
					open={open}
					onClose={handleClose}
					className="dialog_ alertDataDialog"
				>
					<DialogContent>
						<div className="alertHeader">
							<h4 className="dialogHeader">
								Add a trading wallet.
							</h4>
						</div>
						<div>
							<p>
								Readyswap recommends you use a different wallet
								from the Account Creation & Login wallet, to
								maximize the security of your funds.
							</p>
							<p>
								To <b>set up a new wallet</b>, click this{" "}
								<span
									className="link_"
									onClick={() =>
										callNewSite(
											"https://support.metamask.io/hc/en-us/articles/360015289452-How-to-create-an-additional-account-in-your-wallet#:~:text=Either%20tap%20the%20account%20icon,the%20account%20to%20change%20it."
										)
									}
								>
									<b>link</b>
								</span>{" "}
								to take you to the Metamask Browser Extension.
							</p>
							<p>
								Need to know how to <b>find your private key</b>{" "}
								for Metamask? Click this{" "}
								<span
									className="link_"
									onClick={() =>
										callNewSite(
											"https://support.metamask.io/hc/en-us/articles/360015289632-How-to-export-an-account-s-private-key"
										)
									}
								>
									<b>Link</b>
								</span>
							</p>
						</div>
						<div className="boxB mempoolAlertBox">
							<Grid container spacing={1} className="dialog_form">
								<Grid
									item
									xs={12}
									sm={3}
									className="contentCenter"
								>
									<FormLabel>Wallet Name</FormLabel>
								</Grid>
								<Grid
									item
									xs={12}
									sm={9}
									className="contentCenter"
								>
									<CssTextField
										className="formInput"
										value={name}
										onChange={(e) =>
											setName(e.target.value)
										}
									/>
									<Tooltip_
										title="Name your wallet, particularly useful when operating multiple wallets."
										enterTouchDelay={0}
									>
										<InfoIcon className="infoIcon" />
									</Tooltip_>
								</Grid>
							</Grid>

							<Grid container spacing={1} className="dialog_form">
								<Grid
									item
									xs={12}
									sm={3}
									className="contentCenter"
								>
									<FormLabel>Public Address</FormLabel>
								</Grid>
								<Grid
									item
									xs={12}
									sm={9}
									className="contentCenter"
								>
									<CssTextField
										className="formInput"
										value={publicKey}
										onChange={(e) =>
											setPublicKey(e.target.value)
										}
									/>
									<Tooltip_
										title="This is your Public Address from Metamask or wallet of your choice. Create a wallet that you wish to use for trading. It is recommended, for extra security, that you do not use the same wallet that you used for ReadySwap Account Creation & Login. "
										enterTouchDelay={0}
									>
										<InfoIcon className="infoIcon" />
									</Tooltip_>
								</Grid>
							</Grid>
							<Grid container spacing={1} className="dialog_form">
								<Grid
									item
									xs={12}
									sm={3}
									className="contentCenter"
								>
									<FormLabel>Private Key</FormLabel>
								</Grid>
								<Grid
									item
									xs={12}
									sm={9}
									className="contentCenter"
								>
									<CssTextField
										className="formInput"
										value={pk}
										onChange={(e) => setPK(e.target.value)}
										type="password"
									/>
									<Tooltip_
										title="Private key is needed to be added to the wallet creation to be able to perform transactions. Your private key is encrypted with AES 256-bit encryption and ReadySwap is unable to view it or help you to recover it if lost."
										enterTouchDelay={0}
									>
										<InfoIcon className="infoIcon" />
									</Tooltip_>
								</Grid>
							</Grid>
						</div>
					</DialogContent>
					<DialogActions>
						<div className="historyBtn">
							<Button
								variant="outlined"
								className="btnCancel"
								onClick={() => handleClose()}
							>
								Exit
							</Button>
							<Button
								variant="outlined"
								className="btnCancel"
								onClick={() => addNewWallet()}
							>
								Save
							</Button>
						</div>
					</DialogActions>
				</Dialog>
			)}
		</div>
	);
};

export default DialogAddWallet;
