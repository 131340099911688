import React, { useContext, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { browserName } from "react-device-detect";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import AppContext from "../context/AppContext";
import axiosI from "../api/api";
import BarLoader from "react-spinners/BarLoader";
window.recaptchaOptions = {
	useRecaptchaNet: true,
};
const ReCaptcha = () => {
	const { reCaptcha } = useContext(AppContext);
	const navigate = useNavigate();
	const [apiKey, setApiKey] = useState(
		"c5ecb740-7549-45eb-b9d6-774156ae77a2"
		//"10000000-ffff-ffff-ffff-000000000001"
	);
	const [token, setToken] = useState(null);
	const [v2, setV2] = useState(false);
	const reRef = useRef();
	const onLoad = (d) => {
		// this reaches out to the hCaptcha JS API and runs the
		// execute function on it. you can use other functions as
		// documented here:
		// https://docs.hcaptcha.com/configuration#jsapi

		reRef.current.execute();
	};

	useEffect(() => {
		if (token) {
			checkReCAPTCHA(token, "V3");
		}
	}, [token]);

	const checkReCAPTCHA = async (token, type) => {
		axiosI
			.post("/api/start", {
				token: token,
				browser: browserName,
				type,
			})
			.then((data) => {
				reCaptcha.setReCAPTCHA({
					type: "set",
					key: token,
					data: type,
				});
				navigate("/");
			})
			.catch((error) => {
				setV2(true);
				//console.log("v2", v2);
				//toast.error("Network error! Please refresh the site.");
				if (error.response.data.error.code == 5) {
					reCaptcha.setReCAPTCHA({ type: "reset" });
				}
			});
	};

	const newReCAPTCHA = () => {
		reRef.current.reset();
	};
	const handleError = (err) => {
		console.log("err", err);
	};
	return (
		<div className="startBG">
			<div className="startPage">
				<div className="startPageBox">
					<div className="startPageName">
						Ready
						<span className="startPageSubName">Swap</span>
					</div>
					<div className="startPageLoader">
						<HCaptcha
							sitekey={apiKey}
							onLoad={onLoad}
							onVerify={setToken}
							onError={handleError}
							ref={reRef}
							theme="dark"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReCaptcha;
