import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { browserName } from "react-device-detect";
import AppContext from "../context/AppContext";
import { Web3Button } from "@web3modal/react";
import { useAccount, useNetwork, useSignMessage } from "wagmi";

import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import axiosI from "../api/api";
import { sendToken } from "../utils/functions";
import sortVideo from "../img/ReadySwap.GIF";
import { sleepMs } from "../utils/functions";

const WalletConnector = () => {
	const navigate = useNavigate();
	const { account, reCaptcha } = useContext(AppContext);
	const { chain, chains } = useNetwork();
	const { address, isConnected } = useAccount();
	const [message, setMessage] = useState("");
	const { data, isError, isLoading, isSuccess, signMessage } = useSignMessage(
		{
			message,
			onSuccess(data) {
				setSignature(data);
				auth(data);
			},
			onError(error) {
				window.location.reload();
			},
		}
	);

	const [nonce, setNonce] = useState();
	const [signature, setSignature] = useState();

	useEffect(() => {
		if (isConnected && !!address) {
			checkWalletSession();
		}
	}, [address, chain]);

	const checkWalletSession = () => {
		axiosI
			.post("/api/checkAuth", {
				token: reCaptcha.getReCAPTCHA.key,
				address,
				browser: browserName,
				type: reCaptcha.getReCAPTCHA.type,
			})
			.then((res) => {
				try {
					account.setMainWallet({
						type: "set",
						data: {
							address,
							network: chain?.id,
						},
					});

					if (!!res.data.data.token) {
						if (
							res.data.data.token === reCaptcha.getReCAPTCHA.key
						) {
							navigate("/main");
						}
					} else {
						setNonce(res.data.data);
						setMessage(
							`\nAuthorize login with ReadySwap on this Wallet!\n\nFor trading after login create new trading wallets. Click to confirm acceptance of Readyswap Terms of Service  and Privacy Policy. (More info on the website.)\n\nImportant: This request will not trigger a blockchain transaction or cost any gas fees. Your authentication status will be periodically reset, as an additional security measure to you.\n\n\n\n ${res.data.data}`
						);
					}
				} catch (error) {}
			})
			.catch((error) => {
				if (error.response.data.error.code == 9) {
					toast.error(
						"Cookies are not enabled in your browser. On mobile, cookies are needed to manage the wallet connection. Please enable!"
					);
				} else if (error.response.data.error.code == 5) {
					navigate("/logout");
				} else {
					toast.error("Network error! Please refresh the site.");
				}
			});
	};

	const handleLogin = async () => {
		try {
			await signMessage({ message });
		} catch (error) {
			//console.log("handleLogin", handleLogin);
		}
	};

	const auth = (data) => {
		try {
			axiosI
				.post("/api/login", {
					signature: data,
					address,
					token: reCaptcha.getReCAPTCHA.key,
					nonce,
				})
				.then((res) => {
					try {
						sessionStorage.setItem("token", res.data.data);
						sendToken(res.data.data);
						// redirect main
						navigate("/main");
					} catch (error) {}
				})
				.catch((error) => {
					toast.error("Network error! Please refresh the site.");
					if (error.response.data.error.code == 5) {
						navigate("/logout");
					}
				});
		} catch (error) {}
	};
	return (
		<div className="login">
			<div className="swap_header">
				<div className="swap_title">ReadySwap</div>
			</div>
			<div className="swapForm swapConnectWallet">
				<div className="loginBox">
					<div className="loginMainWallet">
						<Web3Button />
					</div>
					{!!nonce && (
						<Button
							variant="contained"
							className="btnNew"
							onClick={handleLogin}
						>
							{isLoading ? "Sign Message" : "Login"}
						</Button>
					)}
				</div>
			</div>
			<div className="swapInfoMsg">
				Fast settings, fast speed for only 1% fee!
			</div>
			<div className="swapInfo">
				<div className="startgifBox">
					<img
						src={sortVideo}
						alt="loading..."
						className="startgif"
					/>
				</div>
			</div>
		</div>
	);
};

export default WalletConnector;
