import "./Fonts.css";
import "./App.css";
import React, { useEffect, useReducer, useState } from "react";
import AppContext from "./context/AppContext";
import { createTheme, ThemeProvider } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	NavLink,
	HashRouter,
} from "react-router-dom";

import {
	EthereumClient,
	w3mConnectors,
	w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal, useWeb3ModalTheme } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { mainnet } from "wagmi/chains";

import reducerReCAPTCHA, { initReCAPTCHA } from "./reducers/reCaptcha";
import reducerMainWallet, { initMainWallet } from "./reducers/mainWallet";
import reducerWallets, { initWallets } from "./reducers/wallets";
import reducerUserSettings, { initUserSettings } from "./reducers/userSettings";

import WalletConnector from "./components/WalletConnector";
import Main from "./components/Main";
import Logout from "./components/Logout";
import ReCaptcha from "./components/ReCaptcha";

const reactTheme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 440,
			md: 900,
			lg: 1200,
			xl: 1536,
		},
	},
});
localStorage.setItem("wagmi.injected.shimDisconnect", "true");
const chains = [mainnet];
const projectId = "e34afb9cba0d97c7c171d01a7194615b";

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);

const wagmiConfig = createConfig({
	autoConnect: true,
	connectors: w3mConnectors({ projectId, version: 1, chains }),
	publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

function App() {
	const [connected, setConnected] = useState(false);
	const [getReCAPTCHA, setReCAPTCHA] = useReducer(
		reducerReCAPTCHA,
		initReCAPTCHA
	);
	const [mainWallet, setMainWallet] = useReducer(
		reducerMainWallet,
		initMainWallet
	);
	const [wallets, setWallets] = useReducer(reducerWallets, initWallets);
	const [settings, setSettings] = useReducer(
		reducerUserSettings,
		initUserSettings
	);
	const { theme, setTheme } = useWeb3ModalTheme();

	useEffect(() => {
		setConnected(!!mainWallet.address);
	}, [mainWallet]);
	useEffect(() => {
		window.addEventListener("storage", storageEventHandler, false);
		setTheme({
			themeMode: "dark",
			themeVariables: {
				"--w3m-font-family": "Poppins, sans-serif",
				"--w3m-accent-color": "#30bd92",
				"--w3m-background-color": "#1a1a1a",
				"--w3m-z-index": "9999",
			},
		});
	}, []);

	const storageEventHandler = (event) => {
		if (event.key === "CREDENTIALS_SHARING_READYSWAP" && !!event.newValue) {
			sessionStorage.setItem("token", event.newValue);
		}

		if (event.key === "REMOVE_SHARING_READYSWAP") {
			sessionStorage.removeItem("token");
		}
	};

	return (
		<AppContext.Provider
			value={{
				reCaptcha: { getReCAPTCHA, setReCAPTCHA },
				account: {
					mainWallet,
					setMainWallet,
					wallets,
					setWallets,
					settings,
					setSettings,
				},
			}}
		>
			<ToastContainer
				position="bottom-right"
				autoClose={4000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				style={{ width: "400px" }}
			/>
			<ThemeProvider theme={reactTheme}>
				<WagmiConfig config={wagmiConfig}>
					<div id="root">
						<Router history={HashRouter}>
							{!!!getReCAPTCHA.key ? (
								<ReCaptcha />
							) : (
								<div className="swapmain">
									<Paper
										elevation={6}
										className="box boxMargin swap"
									>
										<Routes>
											<Route
												exact
												path="/"
												element={<WalletConnector />}
											/>
											<Route
												exact
												path="/main"
												element={<Main />}
											/>
											<Route
												exact
												path="/logout"
												element={<Logout />}
											/>
										</Routes>
									</Paper>
								</div>
							)}
						</Router>
					</div>
				</WagmiConfig>
				<Web3Modal
					projectId={projectId}
					ethereumClient={ethereumClient}
				/>
			</ThemeProvider>
		</AppContext.Provider>
	);
}

export default App;
