import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../context/AppContext";
import { toast } from "react-toastify";

import axiosI from "../api/api";
import { useAccount } from "wagmi";
import IconButton from "@mui/material/IconButton";

import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import SaveIcon from "@mui/icons-material/Save";

import BuyPanel from "./BuyPanel";
import SellPanel from "./SellPanel";
import TradesPanel from "./TradesPanel";
import TasksPanel from "./TasksPanel";
import {
	sendToken,
	setDefault,
	getDefault,
	checkGASBalance,
	checkInvestment,
} from "../utils/functions";

import HistoryIcon from "@mui/icons-material/History";
import DialogHistory from "./DialogHistory";
import DialogPools from "./DialogPools";
import DialogMev from "./DialogMev";
import LockIcon from "@mui/icons-material/Lock";
import DialogAddTrades from "./DialogAddTrades";
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && <div>{children}</div>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};
const regex_address = new RegExp(/^0x[A-F0-9]{40}$/, "i");

const isAddress = (input) => {
	return regex_address.test(input);
};

let _publicKey = "";
const Snipe = ({
	network,
	setNetwork,
	snipeWallet,
	handleRefreshWallet,
	getTokens,
}) => {
	const navigate = useNavigate();
	const { account, reCaptcha } = useContext(AppContext);
	const { address } = useAccount({
		onDisconnect() {
			navigate("/logout");
		},
	});

	// Megnyitáskor fut le

	useEffect(() => {
		if (!!snipeWallet) {
			_publicKey = snipeWallet;
		}
	}, [snipeWallet]);

	useEffect(() => {
		if (!!_publicKey) {
			if (!!account.wallets[_publicKey]?.default.allInv) {
				let _allInv = parseFloat(
					account.wallets[_publicKey].default.allInv
				);
				setDefault(_publicKey, { _allInv });
			} else {
				setDefault(_publicKey, { _allInv: 0 });
			}
		}
	}, [account.wallets[_publicKey]?.default.allInv, snipeWallet]);
	useEffect(() => {
		if (!!account.wallets[_publicKey]?.default.contract && !!_publicKey) {
			let _contract = account.wallets[_publicKey].default.contract;
			setDefault(_publicKey, { _contract });
			if (
				isAddress(account.wallets[_publicKey].default.contract) &&
				account.wallets[_publicKey].default.poolsData.length === 0
			) {
				if (account.wallets[_publicKey].default.antiRevert) {
					toast.warn("Reminder! Anti revert function sending 2 tx!");
				}
				loadContractData(account.wallets[_publicKey].default.contract);
			}
			if (account.wallets[_publicKey].default.contract === "") {
				handleRemove();
			}
		}
	}, [account.wallets[_publicKey]?.default.contract, snipeWallet]);
	useEffect(() => {
		if (!!account.wallets[_publicKey]?.default.tcontract && !!_publicKey) {
			if (isAddress(account.wallets[_publicKey].default.tcontract)) {
				loadContractData(account.wallets[_publicKey].default.tcontract);
			}
			if (account.wallets[_publicKey].default.tcontract === "") {
				setVar({
					tname: "",
					tprice: "",
					tmc: "",
					tprofit: "",
					tstabil: "",
					tswap: "",
					tfee: "",
				});
			}
		}
	}, [account.wallets[_publicKey]?.default.tcontract, snipeWallet]);
	useEffect(() => {
		if (!!account.wallets[_publicKey]?.default && !!_publicKey) {
			let _autoApprove = account.wallets[_publicKey].default.autoApprove;
			setDefault(_publicKey, { _autoApprove });
		}
	}, [account.wallets[_publicKey]?.default.autoApprove, snipeWallet]);

	useEffect(() => {
		if (!!account.wallets[_publicKey]?.default.pair && !!_publicKey) {
			let _pair = account.wallets[_publicKey].default.pair;
			setDefault(_publicKey, { _pair });
		}
	}, [account.wallets[_publicKey]?.default.pair, snipeWallet]);
	useEffect(() => {
		if (!!account.wallets[_publicKey]?.default.router && !!_publicKey) {
			let _router = account.wallets[_publicKey].default.router;
			setDefault(_publicKey, { _router });
		}
	}, [account.wallets[_publicKey]?.default.router, snipeWallet]);
	useEffect(() => {
		if (!!account.wallets[_publicKey]?.default.fee && !!_publicKey) {
			let _fee = account.wallets[_publicKey].default.fee;
			setDefault(_publicKey, { _fee });
		}
	}, [account.wallets[_publicKey]?.default.fee, snipeWallet]);
	useEffect(() => {
		if (!!account.wallets[_publicKey]?.default.swap && !!_publicKey) {
			let _swap = account.wallets[_publicKey].default.swap;
			setDefault(_publicKey, { _swap });
		}
	}, [account.wallets[_publicKey]?.default.swap, snipeWallet]);
	useEffect(() => {
		if (!!account.wallets[_publicKey]?.default && !!_publicKey) {
			if (account.wallets[_publicKey]?.default.mev == false) {
				setVar({
					mevGas: "20",
					mevCheck: true,
				});
			} else {
				const check = checkGASBalance(account.wallets[_publicKey]);
				/* const valueAmount = checkInvestment(
					account.wallets[_publicKey]
				); */
				if (
					!check /* ||
					!valueAmount ||
					account.wallets[_publicKey]?.default.pair !=
						"0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2" */
				) {
					setVar({
						mevCheck: false,
					});
				} else {
					setVar({
						mevCheck: true,
					});
				}
			}
		}
	}, [
		account.wallets[_publicKey]?.default.buyGasLimit,
		account.wallets[_publicKey]?.default.mev,
		account.wallets[_publicKey]?.default.mevGas,
		account.wallets[_publicKey]?.default.value,
		//account.wallets[_publicKey]?.default.amount,
		//account.wallets[_publicKey]?.default.pair,
		snipeWallet,
	]);
	useEffect(() => {
		if (
			account.wallets[_publicKey]?.default.buyToken &&
			account.wallets[_publicKey]?.default.amount
		) {
			const valueAmount = checkInvestment(account.wallets[_publicKey]);

			if (valueAmount) {
				setVar({
					amountCheck: true,
				});
			} else {
				setVar({
					amountCheck: false,
				});
			}
		} else {
			setVar({
				amountCheck: true,
			});
		}
	}, [
		account.wallets[_publicKey]?.default.amount,
		account.wallets[_publicKey]?.default.amount.buyToken,
		account.wallets[_publicKey]?.default.value,
	]);

	const setVar = (obj) => {
		if (!!!_publicKey) return false;
		account.setWallets({
			type: "setDefault",
			wallet: _publicKey,
			data: obj,
		});
	};
	const setVarD = (wallet, obj) => {
		if (!!!wallet) return false;
		account.setWallets({
			type: "setDefault",
			wallet: wallet,
			data: obj,
		});
	};

	const loadContractData = (contract) => {
		let _default = getDefault();

		if (!_default[_publicKey]._getPool) {
			_default[_publicKey]._getPool = true;
			setDefault(_publicKey, { ..._default[_publicKey] });
			return false;
		}
		axiosI
			.post("/api/getPools", { contract })
			.then((res) => {
				const num = res.data.data.pools.length;
				if (num > 0) {
					if (num === 1) {
						setPoolData(res.data.data.pools[0]);
					} else {
						setVar({
							poolsData: res.data.data.pools,
							openPoolList: true,
						});
					}
				}
				if (!!res.data.data.name) {
					if (!!account.wallets[_publicKey]?.default.openTrade) {
						setVar({
							tname: res.data.data.name,
						});
					} else {
						setVar({
							name: res.data.data.name,
						});
					}
				}
			})
			.catch((error) => {
				toast.error("Network error! Please refresh the site.");
				if (error.response.data.error.code == 5) {
					navigate("/logout");
				}
			});
		_default[_publicKey]._getPool = true;
		setDefault(_publicKey, { ..._default[_publicKey] });
	};
	const setPoolData = (data) => {
		try {
			if (!!account.wallets[_publicKey]?.default.openTrade) {
				setVar({
					tfee: data.fee,
					tpairToken: data.value,
					tswap: data.type,
					trouter:
						data.type === "V3"
							? "0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45"
							: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
				});
				setTimeout(() => {
					loadTokenData();
				}, 100);
			} else {
				setVar({
					fee: data.fee,
					pair: data.value,
					swap: data.type,
					router:
						data.type === "V3"
							? "0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45"
							: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
				});
			}
		} catch (error) {}
	};
	const loadTokenData = () => {
		const data = {
			contract: account.wallets[_publicKey]?.default.tcontract,
			publicKey: _publicKey,
			swap: account.wallets[_publicKey]?.default.tswap,
			fee: account.wallets[_publicKey]?.default.tfee,
		};
		axiosI
			.post("/api/tokeninfo", data)
			.then((res) => {
				const rData = res.data.data;

				setVar({
					tprice: rData.price,
					tmc: rData.mc,
					tprofit: rData.value,
					tstabil: rData.stabil,
				});
			})
			.catch((error) => {
				toast.error("Network error! Please refresh the site.");
				if (error.response.data.error.code == 5) {
					navigate("/logout");
				}
			});
	};
	const reNewSession = (token) => {
		sessionStorage.setItem("token", token);
		sendToken(token);
	};
	const buy = () => {
		let _default = getDefault();
		if (_default[_publicKey]._proc) return false;
		setDefault(_publicKey, {
			_proc: true,
		});
		let methodIDs =
			!!account.wallets[_publicKey].default.methods.length > 0
				? account.wallets[_publicKey].default.methods.map((m) => {
						if (!!m.func_hash) {
							return m.func_hash;
						} else {
							if (m.length > 0) {
								if (m[0] === "0") {
									return m;
								}
							}
						}
				  })
				: [];

		if (!!account.wallets[_publicKey].default.methodsString) {
			const data = !!account.wallets[_publicKey].default.methodsString
				? account.wallets[_publicKey].default.methodsString
						.split(",")
						.map((m) => m.trim())
				: [];
			methodIDs = [...methodIDs, ...data];
		}

		for (
			let index = 0;
			index < Object.keys(account.wallets).length;
			index++
		) {
			const p = Object.keys(account.wallets)[index];
			const wallet = account.wallets[p];
			if (!!account.wallets[snipeWallet].tradeConnect) {
				if (!!wallet.tradeConnect) {
					setVarD(wallet.snipe_wallet, {
						processing: true,
					});
					setDefault(wallet.snipe_wallet, {
						_processing: true,
					});
					axiosI
						.post("/api/trade", {
							main_wallet: "",
							publicKey: wallet.snipe_wallet,
							privateKey: "",
							contract:
								account.wallets[_publicKey].default.contract,
							sortContract: "",
							methodIDs: methodIDs,
							owner: account.wallets[_publicKey].default.onlyOwner
								? account.wallets[_publicKey].default.owner
								: "",
							gas: {
								autoGwei: methodIDs.length > 0,
								copyMultiple:
									account.wallets[_publicKey].default
										.copyMultipleBuy,
								multiple: !account.wallets[_publicKey].default
									.manualGasBuy
									? account.wallets[_publicKey].default
											.multiple
									: "", //lehet üres
								gasPrice: account.wallets[_publicKey].default
									.manualGasBuy
									? account.wallets[_publicKey].default
											.buyGasPrice
									: "", //lehet üres
								gasLimit:
									account.wallets[_publicKey].default
										.buyGasLimit, //lehet üres
								maxFeePerGas: account.wallets[_publicKey]
									.default.manualGasBuy
									? account.wallets[_publicKey].default
											.buymaxFeePerGas
									: "", //lehet üres
								maxPriorityFeePerGas: account.wallets[
									_publicKey
								].default.manualGasBuy
									? account.wallets[_publicKey].default
											.buymaxPriorityFeePerGas
									: "", //lehet üres
							},
							value: account.wallets[_publicKey].default.value,
							amount: account.wallets[_publicKey].default.amount, //lehet üres
							type: !!account.wallets[_publicKey].default.buyToken
								? "BUY_TOKEN"
								: "BUY_ETH", //BUY_ETH, BUY_TOKEN, SELL, APPROVE
							delay: {
								type: account.wallets[_publicKey].default
									.delayType, //BLOCK, MS
								value: account.wallets[_publicKey].default
									.delayValue,
							},
							slippage:
								account.wallets[_publicKey].default.buySlippage, //lehet üres
							pair: account.wallets[_publicKey].default.pair, //router címe
							router: account.wallets[_publicKey].default.router,
							antiMev:
								account.wallets[_publicKey].default.antiMEV,
							onlyManGas:
								account.wallets[_publicKey].default.onlyManGas,
							prevGenerate:
								!account.wallets[_publicKey].default
									.dynamicNonce,
							txHash: "",
							network:
								account.wallets[_publicKey].default.network,
							swap: account.wallets[_publicKey].default.swap,
							status: 0,
							antiRevert:
								account.wallets[_publicKey].default.antiRevert,
							fee: account.wallets[_publicKey].default.fee,
							mev:
								account.wallets[_publicKey].default.mev &&
								account.wallets[_publicKey].default.mevCheck,
							mevGas: account.wallets[_publicKey].default.mevGas,
						})
						.then((res) => {
							//console.log("trade", res.data.data);
							reNewSession(res.data.data.token);
						})
						.catch((error) => {
							toast.error(
								"Process start error. Click stop and than start trade again."
							);
							if (error.response.data.error.code == 5) {
								navigate("/logout");
							}
						})
						.finally(function () {
							setDefault(wallet.snipe_wallet, {
								_proc: false,
							});
						});
				}
			} else {
				setVar({
					processing: true,
				});
				setDefault(_publicKey, { _processing: true });

				axiosI
					.post("/api/trade", {
						main_wallet: "",
						publicKey: _publicKey,
						privateKey: "",
						contract: account.wallets[_publicKey].default.contract,
						sortContract: "",
						methodIDs: methodIDs,
						owner: account.wallets[_publicKey].default.onlyOwner
							? account.wallets[_publicKey].default.owner
							: "",
						gas: {
							autoGwei: methodIDs.length > 0,
							copyMultiple:
								account.wallets[_publicKey].default
									.copyMultipleBuy,
							multiple: !account.wallets[_publicKey].default
								.manualGasBuy
								? account.wallets[_publicKey].default.multiple
								: "", //lehet üres
							gasPrice: account.wallets[_publicKey].default
								.manualGasBuy
								? account.wallets[_publicKey].default
										.buyGasPrice
								: "", //lehet üres
							gasLimit:
								account.wallets[_publicKey].default.buyGasLimit, //lehet üres
							maxFeePerGas: account.wallets[_publicKey].default
								.manualGasBuy
								? account.wallets[_publicKey].default
										.buymaxFeePerGas
								: "", //lehet üres
							maxPriorityFeePerGas: account.wallets[_publicKey]
								.default.manualGasBuy
								? account.wallets[_publicKey].default
										.buymaxPriorityFeePerGas
								: "", //lehet üres
						},
						value: account.wallets[_publicKey].default.value,
						amount: account.wallets[_publicKey].default.amount, //lehet üres
						type: !!account.wallets[_publicKey].default.buyToken
							? "BUY_TOKEN"
							: "BUY_ETH", //BUY_ETH, BUY_TOKEN, SELL, APPROVE
						delay: {
							type: account.wallets[_publicKey].default.delayType, //BLOCK, MS
							value: account.wallets[_publicKey].default
								.delayValue,
						},
						slippage:
							account.wallets[_publicKey].default.buySlippage, //lehet üres
						pair: account.wallets[_publicKey].default.pair, //router címe
						router: account.wallets[_publicKey].default.router,
						antiMev: account.wallets[_publicKey].default.antiMEV,
						onlyManGas:
							account.wallets[_publicKey].default.onlyManGas,
						prevGenerate:
							!account.wallets[_publicKey].default.dynamicNonce,
						txHash: "",
						network: account.wallets[_publicKey].default.network,
						swap: account.wallets[_publicKey].default.swap,
						status: 0,
						antiRevert:
							account.wallets[_publicKey].default.antiRevert,
						fee: account.wallets[_publicKey].default.fee,
						mev:
							account.wallets[_publicKey].default.mev &&
							account.wallets[_publicKey].default.mevCheck,
						mevGas: account.wallets[_publicKey].default.mevGas,
					})
					.then((res) => {
						//console.log("trade", res.data.data);
						reNewSession(res.data.data.token);
					})
					.catch((error) => {
						toast.error(
							"Process start error. Click stop and than start trade again."
						);
						if (error.response.data.error.code == 5) {
							navigate("/logout");
						}
					})
					.finally(function () {
						setDefault(_publicKey, {
							_proc: false,
						});
					});
				break;
			}
		}
	};
	const saveTrade = (deleteTask = "") => {
		let _default = getDefault();
		if (_default[_publicKey]._proc) return false;
		setDefault(_publicKey, {
			_proc: true,
		});
		if (!!deleteTask) {
			handleDeleteTask(deleteTask);
		}

		for (
			let index = 0;
			index < Object.keys(account.wallets).length;
			index++
		) {
			const p = Object.keys(account.wallets)[index];
			const wallet = account.wallets[p];
			if (!!account.wallets[snipeWallet].tradeConnect) {
				if (!!wallet.tradeConnect) {
					axiosI
						.post("/api/autotrade", {
							main_wallet: "",
							publicKey: wallet.snipe_wallet,
							privateKey: "",
							contract:
								account.wallets[_publicKey].default.tcontract,
							sortContract: "",
							methodIDs: "",
							owner: "",
							gas: {
								autoGwei: false,
								copyMultiple: false,
								multiple: !account.wallets[_publicKey].default
									.tmanualGas
									? account.wallets[_publicKey].default
											.tmultiple
									: "", //lehet üres
								gasPrice: account.wallets[_publicKey].default
									.tmanualGas
									? account.wallets[_publicKey].default
											.tGasPrice
									: "", //lehet üres
								gasLimit:
									account.wallets[_publicKey].default
										.tGasLimit, //lehet üres
								maxFeePerGas: account.wallets[_publicKey]
									.default.tmanualGas
									? account.wallets[_publicKey].default
											.tmaxFeePerGas
									: "", //lehet üres
								maxPriorityFeePerGas: account.wallets[
									_publicKey
								].default.tmanualGas
									? account.wallets[_publicKey].default
											.tmaxPriorityFeePerGas
									: "", //lehet üres
							},
							value: account.wallets[_publicKey].default.tvalue,
							amount: account.wallets[_publicKey].default
								.tsellamount, //lehet üres
							type: account.wallets[_publicKey].default.ttype,
							delay: {
								type: account.wallets[_publicKey].default
									.delayType, //BLOCK, MS
								value: account.wallets[_publicKey].default
									.delayValue,
							},
							slippage:
								account.wallets[_publicKey].default.tslippage, //lehet üres
							pair: account.wallets[_publicKey].default.tpair, //router címe
							router: account.wallets[_publicKey].default.trouter,
							antiMev:
								account.wallets[_publicKey].default.tantiMEV,
							onlyManGas:
								account.wallets[_publicKey].default.onlyManGas,
							prevGenerate:
								!account.wallets[_publicKey].default
									.dynamicNonce,
							txHash: "",
							network:
								account.wallets[_publicKey].default.network,
							swap: account.wallets[_publicKey].default.tswap,
							status: 0,
							antiRevert:
								account.wallets[_publicKey].default.antiRevert,
							fee: account.wallets[_publicKey].default.tfee,
							mev:
								account.wallets[_publicKey].default.mev &&
								account.wallets[_publicKey].default.mevCheck,
							mevGas: account.wallets[_publicKey].default.mevGas,
							low_perc:
								account.wallets[_publicKey].default.tperc[0],
							high_perc:
								account.wallets[_publicKey].default.tperc[1],
							name: account.wallets[_publicKey].default.tname,
							task_value: 0,
							start_profit: 0,
							perc: account.wallets[_publicKey].default.tpercnum,
							interval: account.wallets[_publicKey].default.tint,
							stoploss:
								account.wallets[_publicKey].default.stopLoss,
							task_type:
								account.wallets[_publicKey].default.ttask,
							cond_tsl:
								account.wallets[_publicKey].default.cond_tsl,
						})
						.then((res) => {
							//console.log("trade", res.data.data);
							reNewSession(res.data.data.token);
							handleLoadTasks();
						})
						.catch((error) => {
							toast.error(
								"Process start error. Click stop and than start trade again."
							);
							if (error.response.data.error.code == 5) {
								navigate("/logout");
							}
						})
						.finally(function () {
							setDefault(wallet.snipe_wallet, {
								_proc: false,
							});
						});
				}
			} else {
				axiosI
					.post("/api/autotrade", {
						main_wallet: "",
						publicKey: _publicKey,
						privateKey: "",
						contract: account.wallets[_publicKey].default.tcontract,
						sortContract: "",
						methodIDs: "",
						owner: "",
						gas: {
							autoGwei: false,
							copyMultiple: false,
							multiple: !account.wallets[_publicKey].default
								.tmanualGas
								? account.wallets[_publicKey].default.tmultiple
								: "", //lehet üres
							gasPrice: account.wallets[_publicKey].default
								.tmanualGas
								? account.wallets[_publicKey].default.tGasPrice
								: "", //lehet üres
							gasLimit:
								account.wallets[_publicKey].default.tGasLimit, //lehet üres
							maxFeePerGas: account.wallets[_publicKey].default
								.tmanualGas
								? account.wallets[_publicKey].default
										.tmaxFeePerGas
								: "", //lehet üres
							maxPriorityFeePerGas: account.wallets[_publicKey]
								.default.tmanualGas
								? account.wallets[_publicKey].default
										.tmaxPriorityFeePerGas
								: "", //lehet üres
						},
						value: account.wallets[_publicKey].default.tvalue,
						amount: account.wallets[_publicKey].default.tsellamount, //lehet üres
						type: account.wallets[_publicKey].default.ttype,
						delay: {
							type: account.wallets[_publicKey].default.delayType, //BLOCK, MS
							value: account.wallets[_publicKey].default
								.delayValue,
						},
						slippage: account.wallets[_publicKey].default.tslippage, //lehet üres
						pair: account.wallets[_publicKey].default.tpair, //router címe
						router: account.wallets[_publicKey].default.trouter,
						antiMev: account.wallets[_publicKey].default.tantiMEV,
						onlyManGas:
							account.wallets[_publicKey].default.onlyManGas,
						prevGenerate:
							!account.wallets[_publicKey].default.dynamicNonce,
						txHash: "",
						network: account.wallets[_publicKey].default.network,
						swap: account.wallets[_publicKey].default.tswap,
						status: 0,
						antiRevert:
							account.wallets[_publicKey].default.antiRevert,
						fee: account.wallets[_publicKey].default.tfee,
						mev:
							account.wallets[_publicKey].default.mev &&
							account.wallets[_publicKey].default.mevCheck,
						mevGas: account.wallets[_publicKey].default.mevGas,
						low_perc: account.wallets[_publicKey].default.tperc[0],
						high_perc: account.wallets[_publicKey].default.tperc[1],
						name: account.wallets[_publicKey].default.tname,
						task_value: 0,
						start_profit: 0,
						perc: account.wallets[_publicKey].default.tpercnum,
						interval: account.wallets[_publicKey].default.tint,
						stoploss: account.wallets[_publicKey].default.stopLoss,
						task_type: account.wallets[_publicKey].default.ttask,
						cond_tsl: account.wallets[_publicKey].default.cond_tsl,
					})
					.then((res) => {
						//console.log("trade", res.data.data);
						reNewSession(res.data.data.token);
						handleLoadTasks();
					})
					.catch((error) => {
						toast.error("Process save error. Click save again.");
						if (error.response.data.error.code == 5) {
							navigate("/logout");
						}
					})
					.finally(function () {
						setDefault(_publicKey, {
							_proc: false,
						});
					});
				break;
			}
		}
	};
	const saveFomo = (deleteTask = "") => {
		let _default = getDefault();
		if (_default[_publicKey]._proc) return false;
		setDefault(_publicKey, {
			_proc: true,
		});
		if (!!deleteTask) {
			handleDeleteTask(deleteTask);
		}

		for (
			let index = 0;
			index < Object.keys(account.wallets).length;
			index++
		) {
			const p = Object.keys(account.wallets)[index];
			const wallet = account.wallets[p];
			if (!!account.wallets[snipeWallet].tradeConnect) {
				if (!!wallet.tradeConnect) {
					axiosI
						.post("/api/autotrade", {
							main_wallet: "",
							publicKey: wallet.snipe_wallet,
							privateKey: "",
							contract: wallet.snipe_wallet,
							sortContract: "",
							methodIDs: "",
							owner: "",
							gas: {
								autoGwei: false,
								copyMultiple: false,
								multiple: !account.wallets[_publicKey].default
									.tmanualGas
									? account.wallets[_publicKey].default
											.tmultiple
									: "", //lehet üres
								gasPrice: account.wallets[_publicKey].default
									.tmanualGas
									? account.wallets[_publicKey].default
											.tGasPrice
									: "", //lehet üres
								gasLimit:
									account.wallets[_publicKey].default
										.tGasLimit, //lehet üres
								maxFeePerGas: account.wallets[_publicKey]
									.default.tmanualGas
									? account.wallets[_publicKey].default
											.tmaxFeePerGas
									: "", //lehet üres
								maxPriorityFeePerGas: account.wallets[
									_publicKey
								].default.tmanualGas
									? account.wallets[_publicKey].default
											.tmaxPriorityFeePerGas
									: "", //lehet üres
							},
							value: account.wallets[_publicKey].default
								.fomoValue,
							amount: account.wallets[_publicKey].default
								.tsellamount, //lehet üres
							type: "BUY",
							delay: {
								type: account.wallets[_publicKey].default
									.delayType, //BLOCK, MS
								value: account.wallets[_publicKey].default
									.delayValue,
							},
							slippage:
								account.wallets[_publicKey].default.tslippage, //lehet üres
							pair: account.wallets[_publicKey].default.tpair, //router címe
							router: account.wallets[_publicKey].default.trouter,
							antiMev:
								account.wallets[_publicKey].default.tantiMEV,
							onlyManGas:
								account.wallets[_publicKey].default.onlyManGas,
							prevGenerate:
								!account.wallets[_publicKey].default
									.dynamicNonce,
							txHash: "",
							network:
								account.wallets[_publicKey].default.network,
							swap: account.wallets[_publicKey].default.tswap,
							status: 0,
							antiRevert:
								account.wallets[_publicKey].default.antiRevert,
							fee: account.wallets[_publicKey].default.tfee,
							mev:
								account.wallets[_publicKey].default.mev &&
								account.wallets[_publicKey].default.mevCheck,
							mevGas: account.wallets[_publicKey].default.mevGas,
							low_perc: 0,
							high_perc: 0,
							name: "",
							task_value:
								account.wallets[_publicKey].default.maxFomoTx,
							start_profit: 0,
							perc: 0,
							interval: false,
							stoploss: false,
							task_type: "FOMO",
							cond_tsl: false,
							volume: account.wallets[_publicKey].default.fomo,
						})
						.then((res) => {
							//console.log("trade", res.data.data);
							reNewSession(res.data.data.token);
							handleLoadTasks();
						})
						.catch((error) => {
							toast.error(
								"Process start error. Click stop and than start trade again."
							);
							if (error.response.data.error.code == 5) {
								navigate("/logout");
							}
						})
						.finally(function () {
							setDefault(wallet.snipe_wallet, {
								_proc: false,
							});
						});
				}
			} else {
				axiosI
					.post("/api/autotrade", {
						main_wallet: "",
						publicKey: _publicKey,
						privateKey: "",
						contract: wallet.snipe_wallet,
						sortContract: "",
						methodIDs: "",
						owner: "",
						gas: {
							autoGwei: false,
							copyMultiple: false,
							multiple: !account.wallets[_publicKey].default
								.tmanualGas
								? account.wallets[_publicKey].default.tmultiple
								: "", //lehet üres
							gasPrice: account.wallets[_publicKey].default
								.tmanualGas
								? account.wallets[_publicKey].default.tGasPrice
								: "", //lehet üres
							gasLimit:
								account.wallets[_publicKey].default.tGasLimit, //lehet üres
							maxFeePerGas: account.wallets[_publicKey].default
								.tmanualGas
								? account.wallets[_publicKey].default
										.tmaxFeePerGas
								: "", //lehet üres
							maxPriorityFeePerGas: account.wallets[_publicKey]
								.default.tmanualGas
								? account.wallets[_publicKey].default
										.tmaxPriorityFeePerGas
								: "", //lehet üres
						},
						value: account.wallets[_publicKey].default.fomoValue,
						amount: account.wallets[_publicKey].default.tsellamount, //lehet üres
						type: "BUY",
						delay: {
							type: account.wallets[_publicKey].default.delayType, //BLOCK, MS
							value: account.wallets[_publicKey].default
								.delayValue,
						},
						slippage: account.wallets[_publicKey].default.tslippage, //lehet üres
						pair: account.wallets[_publicKey].default.tpair, //router címe
						router: account.wallets[_publicKey].default.trouter,
						antiMev: account.wallets[_publicKey].default.tantiMEV,
						onlyManGas:
							account.wallets[_publicKey].default.onlyManGas,
						prevGenerate:
							!account.wallets[_publicKey].default.dynamicNonce,
						txHash: "",
						network: account.wallets[_publicKey].default.network,
						swap: account.wallets[_publicKey].default.tswap,
						status: 0,
						antiRevert:
							account.wallets[_publicKey].default.antiRevert,
						fee: account.wallets[_publicKey].default.tfee,
						mev:
							account.wallets[_publicKey].default.mev &&
							account.wallets[_publicKey].default.mevCheck,
						mevGas: account.wallets[_publicKey].default.mevGas,
						low_perc: 0,
						high_perc: 0,
						name: "",
						task_value:
							account.wallets[_publicKey].default.maxFomoTx,
						start_profit: 0,
						perc: 0,
						interval: false,
						stoploss: false,
						task_type: "FOMO",
						cond_tsl: false,
						volume: account.wallets[_publicKey].default.fomo,
					})
					.then((res) => {
						//console.log("trade", res.data.data);
						reNewSession(res.data.data.token);
						handleLoadTasks();
					})
					.catch((error) => {
						toast.error("Process save error. Click save again.");
						if (error.response.data.error.code == 5) {
							navigate("/logout");
						}
					})
					.finally(function () {
						setDefault(_publicKey, {
							_proc: false,
						});
					});
				break;
			}
		}
	};
	const sell = (aMev = false) => {
		let _default = getDefault();
		if (_default[_publicKey]._proc) return false;
		setDefault(_publicKey, { _proc: true });
		let methodIDs = [];
		/* let methodIDs =
			!!multipleSell.length > 0
				? multipleSell.map((m) => m.func_hash)
				: [];

		if (!!methodsStringSell) {
			const data = !!methodsStringSell
				? methodsStringSell.split(",").map((m) => m.trim())
				: [];
			methodIDs = [...methodIDs, ...data];
		} */
		for (
			let index = 0;
			index < Object.keys(account.wallets).length;
			index++
		) {
			const p = Object.keys(account.wallets)[index];
			const wallet = account.wallets[p];
			if (!!account.wallets[snipeWallet].tradeConnect) {
				if (!!wallet.tradeConnect) {
					axiosI
						.post("/api/trade", {
							main_wallet: "",
							publicKey: wallet.snipe_wallet,
							privateKey: "",
							contract:
								account.wallets[_publicKey].default.contract,
							sortContract: "", // .slice(2, -1)
							methodIDs: methodIDs, // ide több methodID is kerülhet de nem kötelező
							owner: account.wallets[_publicKey].default.onlyOwner
								? account.wallets[_publicKey].default.owner
								: "",
							gas: {
								autoGwei:
									account.wallets[_publicKey].default
										.methodsSell.length > 0,
								copyMultiple:
									account.wallets[_publicKey].default
										.copyMultipleSell,
								multiple: !account.wallets[_publicKey].default
									.manualGasSell
									? account.wallets[_publicKey].default
											.multipleSell
									: "", //lehet üres
								gasPrice: account.wallets[_publicKey].default
									.manualGasSell
									? account.wallets[_publicKey].default
											.sellGasPrice
									: "", //lehet üres
								gasLimit:
									account.wallets[_publicKey].default
										.sellGasLimit, //lehet üres
								maxFeePerGas: account.wallets[_publicKey]
									.default.manualGasSell
									? account.wallets[_publicKey].default
											.sellmaxFeePerGas
									: "", //lehet üres
								maxPriorityFeePerGas: account.wallets[
									_publicKey
								].default.manualGasSell
									? account.wallets[_publicKey].default
											.sellmaxPriorityFeePerGas
									: "", //lehet üres
							},
							value: "0",
							amount: account.wallets[_publicKey].default
								.sellAmount, //lehet üres
							type: "SELL", //BUY_ETH, BUY_TOKEN, SELL, APPROVE
							delay: {
								type: "", //BLOCK, MS
								value: "",
							},
							slippage:
								account.wallets[_publicKey].default
									.sellSlippage, //lehet üres
							pair: account.wallets[_publicKey].default.pair, //router címe
							router: account.wallets[_publicKey].default.router,
							antiMev: aMev,
							onlyManGas: false,
							prevGenerate: false,
							txHash: "",
							network:
								account.wallets[_publicKey].default.network,
							swap: account.wallets[_publicKey].default.swap,
							status: 0,
							fee: account.wallets[_publicKey].default.fee,
						})
						.then((res) => {
							reNewSession(res.data.data.token);
						})
						.catch((error) => {
							toast.error(
								"Process start error. Click stop and than start trade again."
							);
							if (error.response.data.error.code == 5) {
								navigate("/logout");
							}
						})
						.finally(function () {
							setDefault(_publicKey, {
								_proc: false,
							});
						});
				}
			} else {
				axiosI
					.post("/api/trade", {
						main_wallet: "",
						publicKey: _publicKey,
						privateKey: "",
						contract: account.wallets[_publicKey].default.contract,
						sortContract: "", // .slice(2, -1)
						methodIDs: methodIDs, // ide több methodID is kerülhet de nem kötelező
						owner: account.wallets[_publicKey].default.onlyOwner
							? account.wallets[_publicKey].default.owner
							: "",
						gas: {
							autoGwei:
								account.wallets[_publicKey].default.methodsSell
									.length > 0,
							copyMultiple:
								account.wallets[_publicKey].default
									.copyMultipleSell,
							multiple: !account.wallets[_publicKey].default
								.manualGasSell
								? account.wallets[_publicKey].default
										.multipleSell
								: "", //lehet üres
							gasPrice: account.wallets[_publicKey].default
								.manualGasSell
								? account.wallets[_publicKey].default
										.sellGasPrice
								: "", //lehet üres
							gasLimit:
								account.wallets[_publicKey].default
									.sellGasLimit, //lehet üres
							maxFeePerGas: account.wallets[_publicKey].default
								.manualGasSell
								? account.wallets[_publicKey].default
										.sellmaxFeePerGas
								: "", //lehet üres
							maxPriorityFeePerGas: account.wallets[_publicKey]
								.default.manualGasSell
								? account.wallets[_publicKey].default
										.sellmaxPriorityFeePerGas
								: "", //lehet üres
						},
						value: "0",
						amount: account.wallets[_publicKey].default.sellAmount, //lehet üres
						type: "SELL", //BUY_ETH, BUY_TOKEN, SELL, APPROVE
						delay: {
							type: "", //BLOCK, MS
							value: "",
						},
						slippage:
							account.wallets[_publicKey].default.sellSlippage, //lehet üres
						pair: account.wallets[_publicKey].default.pair, //router címe
						router: account.wallets[_publicKey].default.router,
						antiMev: aMev,
						onlyManGas: false,
						prevGenerate: false,
						txHash: "",
						network: account.wallets[_publicKey].default.network,
						swap: account.wallets[_publicKey].default.swap,
						status: 0,
						fee: account.wallets[_publicKey].default.fee,
					})
					.then((res) => {
						reNewSession(res.data.data.token);
					})
					.catch((error) => {
						toast.error(
							"Process start error. Click stop and than start trade again."
						);
						if (error.response.data.error.code == 5) {
							navigate("/logout");
						}
					})
					.finally(function () {
						setDefault(_publicKey, {
							_proc: false,
						});
					});
				break;
			}
		}
	};
	const approve = () => {
		let _default = getDefault();
		_default[_publicKey]._approved.push(
			document.getElementById("contract").value.toLowerCase()
		);
		if (_default[_publicKey]._proc) return false;
		setDefault(_publicKey, {
			_proc: true,
			_approved: _default[_publicKey]._approved,
		});
		for (
			let index = 0;
			index < Object.keys(account.wallets).length;
			index++
		) {
			const p = Object.keys(account.wallets)[index];
			const wallet = account.wallets[p];
			if (!!account.wallets[snipeWallet].tradeConnect) {
				if (!!wallet.tradeConnect) {
					axiosI
						.post("/api/trade", {
							main_wallet: "",
							publicKey: wallet.snipe_wallet, //account.wallets[0]?.snipe_wallet,
							privateKey: "",
							contract: document.getElementById("contract").value,
							sortContract: "", // .slice(2, -1)
							methodIDs: [], // ide több methodID is kerülhet de nem kötelező
							owner: account.wallets[_publicKey].default.onlyOwner
								? account.wallets[_publicKey].default.owner
								: "",
							gas: {
								autoGwei: false,
								copyMultiple: false,
								multiple: !account.wallets[_publicKey].default
									.manualGasSell
									? account.wallets[_publicKey].default
											.multipleSell
									: "", //lehet üres
								gasPrice: account.wallets[_publicKey].default
									.manualGasSell
									? account.wallets[_publicKey].default
											.sellGasPrice
									: "", //lehet üres
								gasLimit:
									account.wallets[_publicKey].default
										.sellGasLimit, //lehet üres
								maxFeePerGas: account.wallets[_publicKey]
									.default.manualGasSell
									? account.wallets[_publicKey].default
											.sellmaxFeePerGas
									: "", //lehet üres
								maxPriorityFeePerGas: account.wallets[
									_publicKey
								].default.manualGasSell
									? account.wallets[_publicKey].default
											.sellmaxPriorityFeePerGas
									: "", //lehet üres
							},
							value: "0",
							amount: "", //lehet üres
							type: "APPROVE", //BUY_ETH, BUY_TOKEN, SELL, APPROVE
							delay: {
								type: "", //BLOCK, MS
								value: "",
							},
							slippage:
								account.wallets[_publicKey].default
									.sellSlippage, //lehet üres
							pair: account.wallets[_publicKey].default.pair, //router címe
							router: "0x2C57f6DFe219bE08d92Ea55F985311ABaEce89A5",
							antiMev: false,
							onlyManGas: false,
							prevGenerate: false,
							txHash: "",
							network:
								account.wallets[_publicKey].default.network,
							swap: account.wallets[_publicKey].default.swap,
							status: 0,
							fee: account.wallets[_publicKey].default.fee,
						})
						.then((res) => {
							reNewSession(res.data.data.token);
						})
						.catch((error) => {
							toast.error(
								"Process start error. Click stop and than start trade again."
							);
							if (error.response.data.error.code == 5) {
								navigate("/logout");
							}
						})
						.finally(function () {
							setDefault(_publicKey, {
								_proc: false,
							});
						});
				}
			} else {
				axiosI
					.post("/api/trade", {
						main_wallet: "",
						publicKey: _publicKey, //account.wallets[0]?.snipe_wallet,
						privateKey: "",
						contract: document.getElementById("contract").value,
						sortContract: "", // .slice(2, -1)
						methodIDs: [], // ide több methodID is kerülhet de nem kötelező
						owner: account.wallets[_publicKey].default.onlyOwner
							? account.wallets[_publicKey].default.owner
							: "",
						gas: {
							autoGwei: false,
							copyMultiple: false,
							multiple: !account.wallets[_publicKey].default
								.manualGasSell
								? account.wallets[_publicKey].default
										.multipleSell
								: "", //lehet üres
							gasPrice: account.wallets[_publicKey].default
								.manualGasSell
								? account.wallets[_publicKey].default
										.sellGasPrice
								: "", //lehet üres
							gasLimit:
								account.wallets[_publicKey].default
									.sellGasLimit, //lehet üres
							maxFeePerGas: account.wallets[_publicKey].default
								.manualGasSell
								? account.wallets[_publicKey].default
										.sellmaxFeePerGas
								: "", //lehet üres
							maxPriorityFeePerGas: account.wallets[_publicKey]
								.default.manualGasSell
								? account.wallets[_publicKey].default
										.sellmaxPriorityFeePerGas
								: "", //lehet üres
						},
						value: "0",
						amount: "", //lehet üres
						type: "APPROVE", //BUY_ETH, BUY_TOKEN, SELL, APPROVE
						delay: {
							type: "", //BLOCK, MS
							value: "",
						},
						slippage:
							account.wallets[_publicKey].default.sellSlippage, //lehet üres
						pair: account.wallets[_publicKey].default.pair, //router címe
						router: "0x2C57f6DFe219bE08d92Ea55F985311ABaEce89A5",
						antiMev: false,
						onlyManGas: false,
						prevGenerate: false,
						txHash: "",
						network: account.wallets[_publicKey].default.network,
						swap: account.wallets[_publicKey].default.swap,
						status: 0,
						fee: account.wallets[_publicKey].default.fee,
					})
					.then((res) => {
						reNewSession(res.data.data.token);
					})
					.catch((error) => {
						toast.error(
							"Process start error. Click stop and than start trade again."
						);
						if (error.response.data.error.code == 5) {
							navigate("/logout");
						}
					})
					.finally(function () {
						setDefault(_publicKey, {
							_proc: false,
						});
					});
				break;
			}
		}
	};
	const approveStabile = (stabile, r) => {
		let _default = getDefault();
		if (_default[_publicKey]._proc) return false;
		setDefault(_publicKey, { _proc: true });
		axiosI
			.post("/api/trade", {
				main_wallet: "",
				publicKey: _publicKey, //account.wallets[0]?.snipe_wallet,
				privateKey: "",
				contract: stabile,
				sortContract: "", // .slice(2, -1)
				methodIDs: [], // ide több methodID is kerülhet de nem kötelező
				owner: account.wallets[_publicKey].default.onlyOwner
					? account.wallets[_publicKey].default.owner
					: "",
				gas: {
					autoGwei: false,
					copyMultiple: false,
					multiple: !account.wallets[_publicKey].default.manualGasSell
						? account.wallets[_publicKey].default.multipleSell
						: "", //lehet üres
					gasPrice: account.wallets[_publicKey].default.manualGasSell
						? account.wallets[_publicKey].default.sellGasPrice
						: "", //lehet üres
					gasLimit: account.wallets[_publicKey].default.sellGasLimit, //lehet üres
					maxFeePerGas: account.wallets[_publicKey].default
						.manualGasSell
						? account.wallets[_publicKey].default.sellmaxFeePerGas
						: "", //lehet üres
					maxPriorityFeePerGas: account.wallets[_publicKey].default
						.manualGasSell
						? account.wallets[_publicKey].default
								.sellmaxPriorityFeePerGas
						: "", //lehet üres
				},
				value: "0",
				amount: "", //lehet üres
				type: "APPROVE", //BUY_ETH, BUY_TOKEN, SELL, APPROVE
				delay: {
					type: "", //BLOCK, MS
					value: "",
				},
				slippage: account.wallets[_publicKey].default.sellSlippage, //lehet üres
				pair: account.wallets[_publicKey].default.pair, //router címe
				router: r,
				antiMev: false,
				onlyManGas: false,
				prevGenerate: false,
				txHash: "",
				network: account.wallets[_publicKey].default.network,
				swap: account.wallets[_publicKey].default.swap,
				status: 0,
				fee: account.wallets[_publicKey].default.fee,
			})
			.then((res) => {
				reNewSession(res.data.data.token);
			})
			.catch((error) => {
				toast.error(
					"Process start error. Click stop and than start trade again."
				);
				if (error.response.data.error.code == 5) {
					navigate("/logout");
				}
			})
			.finally(function () {
				setDefault(_publicKey, {
					_proc: false,
				});
			});
	};
	const stopBuy = () => {
		return new Promise((resolve, reject) => {
			let _default = getDefault();
			if (_default[_publicKey]._proc) {
				//reject(false);
				return false;
			}
			setDefault(_publicKey, { _proc: true });

			let fw = true;
			for (
				let index = 0;
				index < Object.keys(account.wallets).length;
				index++
			) {
				const p = Object.keys(account.wallets)[index];
				const wallet = account.wallets[p];
				if (!!account.wallets[snipeWallet].tradeConnect) {
					if (!!wallet.tradeConnect) {
						fw = false;

						axiosI
							.post("/api/stopTrade", {
								publicKey: wallet.snipe_wallet,
								network: "ETH",
							})
							.then((res) => {
								//toast.success("Successful Stop");
								setVarD(wallet.snipe_wallet, {
									processing: false,
								});

								setDefault(wallet.snipe_wallet, {
									_processing: false,
								});
							})
							.catch((error) => {
								toast.error(
									"Network error! Please refresh the site."
								);
								if (error.response.data.error.code == 5) {
									navigate("/logout");
								}
							})
							.finally(function () {
								setDefault(wallet.snipe_wallet, {
									_proc: false,
								});
								resolve(true);
							});
					}
				}
			}

			if (fw) {
				axiosI
					.post("/api/stopTrade", {
						publicKey: _publicKey,
						network: "ETH",
					})
					.then((res) => {
						//toast.success("Successful Stop");
						setVar({
							processing: false,
						});
						setDefault(_publicKey, { _processing: false });
					})
					.catch((error) => {
						toast.error("Network error! Please refresh the site.");
						if (error.response.data.error.code == 5) {
							navigate("/logout");
						}
					})
					.finally(function () {
						setDefault(_publicKey, {
							_proc: false,
						});
						resolve(true);
					});
			}
		});
	};

	const balance = (wallet) => {
		let _default = getDefault();
		if (!!!_default[wallet]._contract) return false;
		axiosI
			.post("/api/balance", {
				contract: _default[wallet]._contract,
				pair: _default[wallet]._pair,
				router: _default[wallet]._router,
				publicKey: wallet,
				network: "ETH",
				fee: _default[wallet]._fee,
				swap: _default[wallet]._swap,
			})
			.then((res) => {
				//console.log("balance", res.data.data);
			})
			.catch((error) => {
				toast.error("Network error! Please refresh the site.");
				if (error.response.data.error.code == 5) {
					navigate("/logout");
				}
			});
	};
	const stopbalance = (wallet) => {
		axiosI
			.post("/api/stopbalance", { wallet })
			.then((res) => {
				//console.log("stopbalance", res.data.data);
			})
			.catch((error) => {
				toast.error("Network error! Please refresh the site.");
				if (error.response.data.error.code == 5) {
					navigate("/logout");
				}
			});
	};
	const handleSave = () => {
		let _default = getDefault();
		if (_default[_publicKey]._proc) return false;
		setDefault(_publicKey, { _proc: true });
		let tradeConnect = [];
		for (
			let index = 0;
			index < Object.keys(account.wallets).length;
			index++
		) {
			const wallet = Object.keys(account.wallets)[index];

			if (account.wallets[wallet].tradeConnect) {
				tradeConnect.push(wallet);
			}
		}

		axiosI
			.post("/api/saveSettings", {
				network: account.wallets[_publicKey].default.network,
				value: account.wallets[_publicKey].default.value,
				buyToken: account.wallets[_publicKey].default.buyToken,
				buySlippage: account.wallets[_publicKey].default.buySlippage,
				multiple: account.wallets[_publicKey].default.multiple,
				buyGasLimit: account.wallets[_publicKey].default.buyGasLimit,
				buyGasPrice: account.wallets[_publicKey].default.buyGasPrice,
				buymaxFeePerGas:
					account.wallets[_publicKey].default.buymaxFeePerGas,
				buymaxPriorityFeePerGas:
					account.wallets[_publicKey].default.buymaxPriorityFeePerGas,
				antiMEV: account.wallets[_publicKey].default.antiMEV,
				dynamicNonce: account.wallets[_publicKey].default.dynamicNonce,
				copyMultipleBuy:
					account.wallets[_publicKey].default.copyMultipleBuy,
				manualGasBuy: account.wallets[_publicKey].default.manualGasBuy,
				sellSlippage: account.wallets[_publicKey].default.sellSlippage,
				multipleSell: account.wallets[_publicKey].default.multipleSell,
				sellGasLimit: account.wallets[_publicKey].default.sellGasLimit,
				sellGasPrice: account.wallets[_publicKey].default.sellGasPrice,
				sellmaxFeePerGas:
					account.wallets[_publicKey].default.sellmaxFeePerGas,
				sellmaxPriorityFeePerGas:
					account.wallets[_publicKey].default
						.sellmaxPriorityFeePerGas,
				copyMultipleSell:
					account.wallets[_publicKey].default.copyMultipleSell,
				antiMEVSell: account.wallets[_publicKey].default.antiMEVSell,
				manualGasSell:
					account.wallets[_publicKey].default.manualGasSell,
				autoApprove: account.wallets[_publicKey].default.autoApprove,
				antiRevert: account.wallets[_publicKey].default.antiRevert,
				onlyOwner: account.wallets[_publicKey].default.onlyOwner,
				tradeConnect: JSON.stringify(tradeConnect),
				mev: account.wallets[_publicKey].default.mev,
				mevGas: account.wallets[_publicKey].default.mevGas,

				tvalue: account.wallets[_publicKey].default.tvalue,
				tslippage: account.wallets[_publicKey].default.tslippage,
				tmultiple: account.wallets[_publicKey].default.tmultiple,
				tGasLimit: account.wallets[_publicKey].default.tGasLimit,
				tGasPrice: account.wallets[_publicKey].default.tGasPrice,
				tmaxFeePerGas:
					account.wallets[_publicKey].default.tmaxFeePerGas,
				tmaxPriorityFeePerGas:
					account.wallets[_publicKey].default.tmaxPriorityFeePerGas,
				tantiMEV: account.wallets[_publicKey].default.tantiMEV,
				tmanualGas: account.wallets[_publicKey].default.tmanualGas,
				ttype: account.wallets[_publicKey].default.ttype,
				ttask: account.wallets[_publicKey].default.ttask,
				tint: account.wallets[_publicKey].default.tint,
				tperc: JSON.stringify(
					account.wallets[_publicKey].default.tperc
				),
			})
			.then((res) => {
				toast.success("Successful Save");
			})
			.catch((error) => {
				toast.error("Network error! Please refresh the site.");
				if (error.response.data.error.code == 5) {
					navigate("/logout");
				}
			})
			.finally(function () {
				setDefault(_publicKey, {
					_proc: false,
				});
			});
	};
	const handleRemove = () => {
		let fw = true;
		for (
			let index = 0;
			index < Object.keys(account.wallets).length;
			index++
		) {
			const p = Object.keys(account.wallets)[index];
			const wallet = account.wallets[p];
			if (!!account.wallets[snipeWallet].tradeConnect) {
				if (!!wallet.tradeConnect) {
					fw = false;

					setVarD(wallet.snipe_wallet, {
						contract: "",
						owner: "",
						methods: [],
						methodsString: "",
						methodsStringSell: "",
						amount: "",
						allInv: "",
						profitETH: "",
						profitPerc: "",
						name: "",
						simulate: {},
						poolsData: [],
						fee: "",
						swap: "",
						pair: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
						router: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
						processing: false,
					});
					stopbalance(wallet.snipe_wallet);
					if (account.wallets[wallet.snipe_wallet].default.processing)
						stopBuy();

					setDefault(wallet.snipe_wallet, {
						_allInv: 0,
						_profitETH: 0,
						_profitPerc: 0,
						_processing: false,
						_contract: "",
						_autoApprove: false,
						//_approved: [],
						_proc: false,
						_getPool: true,
						_fee: "",
						_pair: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
						_router: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
						_swap: "",
					});
				}
			}
		}

		if (fw) {
			stopbalance(_publicKey);
			if (account.wallets[_publicKey].default.processing) stopBuy();
			setVar({
				contract: "",
				owner: "",
				methods: [],
				methodsString: "",
				methodsStringSell: "",
				amount: "",
				allInv: "",
				profitETH: "",
				profitPerc: "",
				name: "",
				simulate: {},
				poolsData: [],
				fee: "",
				swap: "",
				pair: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
				router: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
				processing: false,
			});
			setDefault(_publicKey, {
				_allInv: 0,
				_profitETH: 0,
				_profitPerc: 0,
				_processing: false,
				_contract: "",
				_autoApprove: false,
				//_approved: [],
				_proc: false,
				_getPool: true,
				_fee: "",
				_pair: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
				_router: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
				_swap: "",
			});
		}
	};

	const handleAutoSet = () => {
		if (!!!document.getElementById("contract").value) return false;
		axiosI
			.post("/api/getContract", {
				contract: document.getElementById("contract").value,
				network,
			})
			.then((res) => {
				//console.log("getContract", res.data.data);
				setBot(res.data.data);
			})
			.catch((error) => {
				toast.error("Network error! Please refresh the site.");
				if (error.response.data.error.code == 5) {
					navigate("/logout");
				}
			});
	};

	const setBot = (res) => {
		if (res.data.length === 0)
			return toast.warn("No Simulator data available");

		const d = res.data[0];

		let m = [];
		if (!!d.open_trade_hash) {
			let mIDs = getConvertMethod(
				res.methods,
				d.open_trade_hash,
				d.needs_open
			);

			m = [...mIDs];
		} else {
			m = [{ func_hash: "0xf305d719", func_name: "Add liquidity" }];
		}

		let sA = "";
		let v = "";
		if (account.wallets[_publicKey].default.buyToken) {
			if (!!d.max_tx_perc) {
				if (parseFloat(d.max_tx_perc) > 2) {
					sA = "0.5";
				} else {
					sA = d.max_tx_perc;
				}

				if (!!!account.wallets[_publicKey].default.value) {
					v = "0.12";
				} else {
					if (
						parseFloat(account.wallets[_publicKey].default.value) <
						0.12
					) {
						toast.warn("ETH value insufficient for swap");
					}
				}
			}
		}

		if (d.canbuy) {
			setVar({
				allMethods: [...res.methods],
				name: d.name,
				simulate: { ...d },
			});

			return toast.warn("Token is live");
		}
		setVar({
			allMethods: [...res.methods],
			name: d.name,
			simulate: { ...d },
			owner: !!d.owner ? d.owner.toLowerCase() : "",
			methods: [...m],
			amount: !!sA ? sA : "",
			value: !!v ? v : account.wallets[_publicKey].default.value,
		});

		for (
			let index = 0;
			index < Object.keys(account.wallets).length;
			index++
		) {
			const p = Object.keys(account.wallets)[index];
			const wallet = account.wallets[p];
			if (!!account.wallets[snipeWallet].tradeConnect) {
				if (!!wallet.tradeConnect) {
					setVarD(wallet.snipe_wallet, {
						allMethods: [...res.methods],
						name: d.name,
						simulate: { ...d },
						owner: !!d.owner ? d.owner.toLowerCase() : "",
						methods: [...m],
						amount: !!sA ? sA : "",
						value: !!v
							? v
							: account.wallets[_publicKey].default.value,
						contract: document.getElementById("contract").value,
					});
				}
			}
		}

		toast.warn("Bot set successfully");
	};
	const getConvertMethod = (methods, str, needs_open) => {
		const data = !!str ? str.split(";").map((m) => m.trim()) : [];

		let d = [];
		for (let index = 0; index < methods.length; index++) {
			const element = methods[index];
			if (!!needs_open) {
				if (element.func_hash === "0xf305d719") {
					d.push(element);
				}
			}
			if (data.indexOf(element.func_hash) !== -1) {
				d.push(element);
			}
		}

		return d;
	};

	const handleHistory = (wallet) => {
		setVar({
			openHistory: true,
		});
	};
	const handleOpenTrade = (token = undefined) => {
		setVar({
			openTrade: true,
		});
	};
	const handleLoadTasks = () => {
		axiosI
			.post("/api/usertasks", {})
			.then(async (res) => {})
			.catch((error) => {
				/* toast.error("Network error! Please refresh the site.");
				if (error.response.data.error.code == 5) {
					navigate("/logout");
				} */
			});
	};
	const handleDeleteTask = (id) => {
		axiosI
			.post("/api/deleteusertasks", { id })
			.then(async (res) => {
				handleLoadTasks();
			})
			.catch((error) => {
				/* toast.error("Network error! Please refresh the site.");
				if (error.response.data.error.code == 5) {
					navigate("/logout");
				} */
			});
	};
	const handleSellTest = () => {
		let _default = getDefault();
		if (_default[_publicKey]._proc) return false;
		setDefault(_publicKey, { _proc: true });

		if (isAddress(account.wallets[_publicKey].default.contract)) {
			axiosI
				.post("/api/sellTest", {
					main_wallet: "",
					pair: account.wallets[_publicKey].default.pair,
					contract: account.wallets[_publicKey].default.contract,
					publickey: _publicKey,
					percent: undefined,
					network: account.wallets[_publicKey].default.network,
				})
				.then(async (res) => {})
				.catch((error) => {
					/* toast.error("Network error! Please refresh the site.");
				if (error.response.data.error.code == 5) {
					navigate("/logout");
				} */
				})
				.finally(function () {
					setDefault(_publicKey, {
						_proc: false,
					});
				});
		}
	};
	return (
		<>
			{!!snipeWallet && (
				<div>
					{Object.keys(account.wallets).length > 0 && (
						<div>
							<DialogPools
								open={
									account.wallets[snipeWallet].default
										.openPoolList
								}
								setVar={setVar}
								data={
									account.wallets[snipeWallet].default
										.poolsData
								}
								setPoolData={setPoolData}
								handleRemove={handleRemove}
							/>

							<DialogHistory
								open={
									account.wallets[snipeWallet].default
										.openHistory
								}
								setVar={setVar}
								wallet={snipeWallet}
							/>
							<DialogMev
								open={
									account.wallets[snipeWallet].default.openMEV
								}
								setVar={setVar}
								publicKey={snipeWallet}
								contract={
									account.wallets[snipeWallet].default
										.contract
								}
								stopBuy={stopBuy}
								buy={buy}
							/>
							<DialogAddTrades
								setVar={setVar}
								open={
									account.wallets[snipeWallet].default
										.openTrade
								}
								fileds={account.wallets[snipeWallet].default}
								saveTrade={saveTrade}
								saveFomo={saveFomo}
							/>
						</div>
					)}

					{Object.keys(account.wallets).length > 0 && (
						<div className="swapBox swapMainContent">
							<AppBar position="static" className="walletTab">
								<Toolbar>
									<div className="walletHeader">
										<span style={{ width: "60px" }}>
											{account.wallets[snipeWallet]
												.tradeNum > 0 && (
												<span className="connectWallets_">
													{
														account.wallets[
															snipeWallet
														].tradeNum
													}
													<LockIcon />
												</span>
											)}
										</span>
										{account.wallets[snipeWallet].name}
										<div>
											<div
												className="swap_header_Icon"
												style={{ marginRight: "10px" }}
											>
												<IconButton
													color="primary"
													onClick={() =>
														handleHistory(
															account.wallets[
																snipeWallet
															].snipe_wallet
														)
													}
												>
													<HistoryIcon />
												</IconButton>
											</div>
											<div className="swap_header_Icon">
												<IconButton
													color="primary"
													onClick={() => handleSave()}
												>
													<SaveIcon />
												</IconButton>
											</div>
										</div>
									</div>
								</Toolbar>
							</AppBar>
							<div>
								<Grid
									container
									spacing={1}
									className="dialog_form"
								>
									<Grid
										item
										sm={12}
										md={6}
										lg={5}
										className=""
									>
										<BuyPanel
											fileds={
												account.wallets[snipeWallet]
													.default
											}
											setVar={setVar}
											buy={buy}
											stopBuy={stopBuy}
											balance={() => balance(_publicKey)}
											handleRemove={handleRemove}
											handleAutoSet={handleAutoSet}
											snipeData={
												account.wallets[snipeWallet]
											}
											handleRefreshWallet={
												handleRefreshWallet
											}
											approveStabile={approveStabile}
										/>
										<SellPanel
											fileds={
												account.wallets[snipeWallet]
													.default
											}
											setVar={setVar}
											sell={sell}
											approve={approve}
											openTrade={handleOpenTrade}
											balance={() => balance(_publicKey)}
											handleSellTest={handleSellTest}
										/>
									</Grid>
									<Grid
										item
										sm={12}
										md={6}
										lg={7}
										style={{ width: "100%" }}
									>
										<TradesPanel
											fileds={
												account.wallets[snipeWallet]
													.default
											}
											setVar={setVar}
											data={account.wallets[snipeWallet]}
											balance={() => balance(_publicKey)}
											getTokens={getTokens}
											openTrade={handleOpenTrade}
											handleLoadTasks={handleLoadTasks}
										/>
										<TasksPanel
											fileds={
												account.wallets[snipeWallet]
													.default
											}
											setVar={setVar}
											data={account.wallets[snipeWallet]}
											openTrade={handleOpenTrade}
											handleLoadTasks={handleLoadTasks}
											handleDeleteTask={handleDeleteTask}
											publicKey={snipeWallet}
										/>
									</Grid>
								</Grid>
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default Snipe;
